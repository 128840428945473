import React, { useState } from "react";
import { makeStyles, Button } from "@material-ui/core";
import {
  Card,
  CardHeader,
  CardContent,
  Typography,
} from "@material-ui/core";
import Collapse from "@material-ui/core/Collapse";
import Style from "./style.module.scss";
import IconButton from "@material-ui/core/IconButton";
import { Link } from "react-router-dom";

import {
  Delete as DeleteIcon, ExpandMore, ExpandLess
} from "@material-ui/icons";

const useStyles = makeStyles(theme => ({
  root: {
    margin: "10px",
    padding: "10px"

    // backgroundColor: "#ccc",
  },
  todoCard: {
    padding: "10px !important",
  },
  progress:{
    backgroundColor: "rgb(245 236 65)",
    alignSelf: 'center',
    padding: "4px !important",
  },
  completed:{
    backgroundColor: "rgb(65 245 95)",
    alignSelf: 'center',
    padding: "4px !important",
  }
}));

export default function ToDo({
  taskTitle,
  taskDetail,
  assignUsers,
  deadline,
  viewUsers,
  id,
  isEditable,
  deleteTodo,
  completed,
}) {
  const classes = useStyles();
  var [expanded, setExpanded] = useState(false);
  var [toggleRemark, setToggleRemark] = useState(false);
  var [remark, setRemark] = useState("");
  const handleExpandClick = () => {
    setExpanded(!expanded)
  };
  return (
    <Card className={classes.root} elevation={3}>
      <Link
        to={`/todo/${id}`}
        style={{
          textDecoration: "none", color: '#333'
        }}
        key={id}
      >
        <div style={{display:'flex'}}>
        <CardHeader
          className={classes.todoCard}
          title={`Task - ${taskTitle}`}
          style={{ padding: "0px" }}
        // subheader={`Created At ${date}`}
        />
        <Typography variant="body1" className={!completed?classes.progress:classes.completed}>
        {!completed?"In Progress":"Completed"}
        </Typography>
        </div>
        

        <CardContent className={classes.todoCard} style={{ padding: "10px" }}>

        <Typography variant="body1">
            <strong> Description -</strong> {taskDetail}
          </Typography>
        </CardContent>

      </Link>


      {isEditable && (
        <>
          <div className={Style.expand}>
            <IconButton
              aria-label="settings"
              onClick={() => handleExpandClick()}
            >
              {expanded ? <ExpandLess /> : <ExpandMore />}
            </IconButton>
          </div>
          <Collapse in={expanded} timeout="auto" unmountOnExit>
            <CardContent className={Style.inner}>

          <Typography variant="body1">
            <strong>Assigned To Users -</strong>

            {assignUsers ? assignUsers.map((eachUser, index) => {
              return (
                <Typography variant="body1">
                  {eachUser}
                </Typography>
              )
            }) : ''}
          </Typography>
          <Typography variant="body1">
            <strong>View Only Users -</strong>
            {viewUsers ? viewUsers.map((eachUser, index) => {
              return (
                <Typography variant="body1">
                  {eachUser}
                </Typography>
              )
            }) : ''}
          </Typography>
          <Typography variant="body1">
            <strong>Deadline -</strong> {deadline}
          </Typography>


              
              <center>
                <Button
                  color="secondary"
                  variant="contained"
                  style={{ marginTop: "1em" }}
                  onClick={() => deleteTodo(id)}
                  startIcon={<DeleteIcon />}
                >
                  Delete
                </Button>
              </center>
            </CardContent>
          </Collapse>
        </>
      )}
      {/* {!toggleRemark ? (
        <Button
          variant="contained"
        
          style={{ marginLeft: "0.5em" }}
          onClick={() => setToggleRemark(true)}
          startIcon={<AddCommentIcon />}
        >
          Remark
        </Button>
      ) : (
        <>
          <CardActions style={{ marginLeft: "5px" }}>
            <Grid container>
              <Grid className="mt-4" item>
                <TextField
                  name="remark"
                  value={remark}
                  variant="outlined"
                  required
                  onChange={e => setRemark(e.target.value)}
                  id="remark"
                  label="Remarks"
                  autoFocus
                />
              </Grid>
              <Grid className="ml-2 mt-4" item>
                <Button
                  variant="contained"
                
                  onClick={() => {
                    setToggleRemark(false);
                    addRemark(remark, id);
                  }}
                  startIcon={<AddIcon />}
                >
                  Done
                </Button>
              </Grid>
              <Grid className="ml-2 mt-4" item>
                <Button
                  variant="contained"
                  color="secondary"
                  onClick={() => setToggleRemark(false)}
                  startIcon={<CloseIcon />}
                >
                  Cancel
                </Button>
              </Grid>
            </Grid>
          </CardActions>
        </>
      )} */}
    </Card>
  );
}
