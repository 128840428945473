import React, { Component } from "react";
import { connect } from "react-redux";
import { getUserProjects } from "../../Redux/projectRedux/projectAction";
import Project from "./Project";
import Notifications from "react-notification-system-redux";
import Loading from "../../Components/Loading";
import { Button } from "@material-ui/core";
import {
  Grid, InputLabel,
  Select,
  FormControl, MenuItem
} from "@material-ui/core";
import { Link } from "react-router-dom";
class ProjectList extends Component {
  constructor(props) {
    super(props);
    const admin = global.config.secureStorage.getItem("is_admin");
    this.state = {
      loading: false,
      isAdmin: admin,
      superProjects: [],
      selectedSuperProject: ''
    };
  }

  toggleLoading = value => {
    this.setState({
      loading: value,
    });
  };

  async componentDidMount() {
    await this.props.getUserProjects(
      global.config.secureStorage.getItem("username")
    );
  }

  async componentDidUpdate() {
    if (this.props.userProjects.length > 0 && this.props.userProjectSuccess && this.state.superProjects.length === 0) {

      let projects = [...this.props.userProjects]
      let tempSProjects = []
      projects.forEach(singleProject => {
        if (singleProject.superProject)
          tempSProjects.push(singleProject.superProject)
      });

      let uniqueArray = tempSProjects.filter(function (item, pos) {
        return tempSProjects.indexOf(item) == pos;
      })
      this.setState({
        superProjects: uniqueArray,
        selectedSuperProject: uniqueArray[0]
      });
    }

  }

  goToAddProject = () => {
    //
    this.props.history.push("/addProject");
  };

  render() {
    // console.log(this.props.userProjects);
    if (this.props.userProjectLoading || this.state.loading) {
      return <Loading loading={true} />;
    }
    if (this.props.userProjectSuccess && this.props.userProjects.length === 0) {
      return (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "column",
            width: "100vw",
            height: "80vh",
          }}
        >
          <h2 style={{ textAlign: "center" }}>
            No projects are assigned to you right now!!
          </h2>
          {this.state.isAdmin && (
            <Button
              variant="outlined"
              style={{ marginTop: "10px" }}
              onClick={this.goToAddProject}
            >
              Add New Project
            </Button>
          )}
        </div>
      );
    }
    if (this.props.userProjectIsError) {
      return <h1>Error</h1>;
    }
    return (
      <div className="container">
        <div item sm={12} style={{ marginTop: '100px', marginBottom: '20px',display:'flex' }}>
          <FormControl fullWidth>
            <InputLabel id="activity" required>
              Super Project
            </InputLabel>
            <Select
              labelId="activity"
              id="activity"
              value={this.state.selectedSuperProject}
              label="activity"
              onChange={event => {
                this.setState({
                  selectedSuperProject: event.target.value
                });
              }}
            >
              {this.state.superProjects ? this.state.superProjects.map(item => (
                <MenuItem value={item}>{item}</MenuItem>
              )) : ''}
            </Select>
          </FormControl>
          <Link to={"./super-project/mis"} onClick={global.config.secureStorage.setItem("superproject", this.state.selectedSuperProject)}>
                   <Button className="ml-4">View Reports</Button>
              </Link>
          <Link to={"./super-project/store"} onClick={global.config.secureStorage.setItem("superproject", this.state.selectedSuperProject)}>
                   <Button className="ml-4">View Store</Button>
              </Link>
        </div>

        <div
          style={{
            display: "flex",
            flexWrap: "wrap",
            alignItems: "center",
            marginTop: "20px",
          }}
        >



          {this.props.userProjects.map((item, index) => (
            (item.superProject == this.state.selectedSuperProject) ?
              <Project
                project={item}
                key={index}
                history={this.props.history}
                setLoading={this.toggleLoading}
                admin={this.state.isAdmin}
              /> : ''
          ))}
        </div>


        <div
          style={{
            marginLeft: "20px",
          }}
        >
          {this.state.isAdmin && (
            <Button onClick={this.goToAddProject}>Add Another Project</Button>
          )}
        </div>

      </div >
    );
  }
}

const mapStateToProps = state => {

  console.log(state.projectReducer.userProjects)
  return {
    user: state.loginReducer.user,
    userProjects: state.projectReducer.userProjects,
    userProjectLoading: state.projectReducer.userProjectLoading,
    userProjectIsError: state.projectReducer.userProjectIsError,
    userProjectSuccess: state.projectReducer.userProjectSuccess,
    notifications: state.notifications,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    getUserProjects: ids => dispatch(getUserProjects(ids)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ProjectList);
