export const GET_ACTIVITY_DETAILS = "GET_ACTIVITY_DETAILS";
export const ERROR_GET_ACTIVITY_DETAILS = "ERROR_GET_ACTIVITY_DETAILS";
export const SUCCESS_GET_ACTIVITY_DETAILS = "SUCCESS_GET_ACTIVITY_DETAILS";
export const SUCCESS_GET_ACTIVITY_UPDATE_DETAILS =
  "SUCCESS_GET_ACTIVITY_UPDATE_DETAILS";
export const SUCCESS_UPDATE_ACTIVITY_DETAILS =
  "SUCCESS_UPDATE_ACTIVITY_DETAILS";
export const SUCCESS_UPDATE_DETAILS = "SUCCESS_UPDATE_DETAILS";
export const ERROR_UPDATE_ACTIVITY_DETAILS = "ERROR_UPDATE_ACTIVITY_DETAILS";
export const SET_LOADING = "SET_LOADING";
export const DONE_UPDATING_ACTIVITY = "DONE_UPDATING_ACTIVITY";
export const CLEAR_CHECKED_ARRAY = "CLEAR_CHECKED_ARRAY";

export const SUCCESS_GET_DEFAULT_ACTIVITY = "SUCCESS_GET_DEFAULT_ACTIVITY";
export const SUCCESS_GET_DEFAULT_ACTIVITY_NAMES =
  "SUCCESS_GET_DEFAULT_ACTIVITY_NAMES";
