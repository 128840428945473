import React, { Component } from "react";
import {
  getMasterListManforce,
  updateManforceItem,
  deleteManforceItem,
} from "../../Redux/storeRedux/storeAction";
import { connect } from "react-redux";
import { useTheme } from "@material-ui/core/styles";
import {
  Dialog,
  TextField,
  DialogActions,
  DialogContent,
  useMediaQuery,
  DialogContentText,
  Button,
  DialogTitle,
  CardContent,
  Typography,
  Grid,
  Card,
} from "@material-ui/core";
import _ from "lodash";
import Editlist from "./Editlist";
import Loading from "../../Components/Loading";
//import Box from '@mui/material/Box';
//import { DataGrid } from '@mui/x-data-grid';
import MUIDataTable from "mui-datatables";
class DisplayManPower extends Component {
  constructor(props) {
    super(props);
    this.state = {
      store: [],
      open: false,
      itemName: "",
      rate: 0,
      unit: "",
      editIndex: 0,
      nameError: false,
    };
  }
  handleFieldsChange = async (name, value) => {
    let found = false;
    if (name === "itemName") {
      this.props.storeData.forEach(material => {
        if (material.materialName === value.trim()) {
          found = true;
          return;
        }
      });
      if (found) {
        this.setState({ nameError: true });
        //return;
      } else {
        this.setState({ nameError: false });
      }
    }
    if (this.state[name] != value)
      await this.setState({
        [name]: value,
      });

    console.log("staet is ", this.state[name], value);
  };
  async componentDidUpdate() {
    if (this.state.store.length !== this.props.manpowerData.length) {
    
      this.setState({
        store: this.props.manpowerData,
      });
    }
  }
  async componentDidMount() {
    await this.props.getMasterListManforce();
    if (!this.props.getStoreLoading) {
      this.setState({
        // store: new_store,
        store: this.props.manpowerData,
      });
    }
  }
  deleteMaterial = i => {
    let id = this.state.store[i]._id;
    var arr = [...this.state.store];
    arr.splice(i, 1);
    this.props.deleteManforceItem(id);
    // this.setState({
    //   store: arr,
    // });
    // console.log("soter is ",this.state.store)
  };
  handleEdit = (itemName, unit, rate, rowIndex) => {
    this.setState({
      itemName,
      rate,
      unit,
      open: true,
      editIndex: rowIndex,
    });
  };
  handleModalOpen = () => {
    this.setState({
      open: false,
      itemName: "",
      rate: 0,
      unit: "",
      editIndex: 0,
      nameError: false,
    });
  };
  handlesubmit = () => {
    if(this.state.nameError) return
    let new_items = this.state.store;
    new_items[this.state.editIndex] = {
      ...new_items[this.state.editIndex],
      manpowerName: this.state.itemName,
      manpowerRate: this.state.rate,
      manpowerUnit: this.state.unit,
    };
    let id = this.state.store[this.state.editIndex]._id;
    this.props.updateManforceItem(id, new_items[this.state.editIndex]);

    this.setState({
      open: false,
      store: new_items,
    });

    //  console.log("we have new sotre",this.state.store)
  };
  columns = [
    // {
    //   name: "id",
    //   label: "ID",
    //   options: {
    //     filter: true,
    //     sort: true,
    //   },
    // },
    {
      name: "manpowerName",
      label: "ManPower",
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: "manpowerUnit",
      label: "Unit",
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: "manpowerRate",
      label: "Rate",
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: "Edit",
      options: {
        customBodyRender: (value, tableMeta) => {
          return (
            <button
              onClick={() => {
                console.log("values of edit", value, tableMeta);
                this.handleEdit(
                  tableMeta.rowData[0],
                  tableMeta.rowData[1],
                  tableMeta.rowData[2],
                  tableMeta.rowIndex
                );
              }}
            >
              Edit
            </button>
          );
        },
      },
    },
    {
      name: "Delete",
      options: {
        customBodyRender: (value, tableMeta) => {
          return (
            <button onClick={() => this.deleteMaterial(tableMeta.rowIndex)}>
              Delete
            </button>
          );
        },
      },
    },
  ];

  render() {
    return (
      <>
        {this.props.getStoreLoading ? (
          <Loading loading={this.props.loading} />
        ) : (
          <div className="container mt-2" style={{ marginBottom: "1em" }}>
            <h3 className="mt-4 mb-3">Labours Registered</h3>
            <Editlist
              itemName={this.state.itemName}
              unit={this.state.unit}
              rate={this.state.rate}
              open={this.state.open}
              handleModalOpen={this.handleModalOpen}
              handlesubmit={this.handlesubmit}
              handleFieldsChange={this.handleFieldsChange}
              nameError={this.state.nameError}
            />
            {this.state.store.length > 0 && (
              <MUIDataTable
                title={`ManPower`}
                data={this.state.store}
                columns={this.columns}
                options={{
                  selectableRows: "none",
                  responsive: "simple",
                  pagination: false,
                  download: false,
                  viewColumns: false,
                  filter: true,
                  print: false,
                }}
              />
            )}
          </div>
        )}
      </>
    );
  }
}
//

const mapStateToProps = state => {
  return {
    //   check weather getStoreloading ??
    getStoreLoading: state.storeReducer.getStoreLoading,
    manpowerData: state.storeReducer.manpowerData,
  };
};

export default connect(mapStateToProps, {
  getMasterListManforce,
  updateManforceItem,
  deleteManforceItem,
})(DisplayManPower);
