import React, { useEffect, useState } from "react";
import {
  Button,
  Grid,
  Typography,
  Snackbar,
  Slide,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  TableContainer,
  Table,
  TableRow,
  TableCell,
  TableHead,
  TableBody,TableFooter
} from "@material-ui/core";
import KeyboardBackspaceIcon from "@material-ui/icons/KeyboardBackspace";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import MUIDataTable from "mui-datatables";
import axios from "axios";
import { withRouter } from "react-router";
import { Alert } from "@material-ui/lab";
import downloadPDF from "../../Components/pdf";
import Style from "./style.module.scss";
import { createMuiTheme, MuiThemeProvider } from "@material-ui/core/styles";

const getMuiTheme = () =>
  createMuiTheme({
    overrides: {
      MuiTableCell: {
        root: {
          border: "1px solid #333",
          borderBottom:  "1px solid #333",
          verticalAlign: "baseline",
          height: "100%",
          padding: "5px",
        },
        body:{
          maxWidth: '600px',
        },
      },

      
      MuiToolbar: {
        height: "40px",
      },
    },
  });

const DailyProgressCards = props => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [openDownloadStarted, setOpenDownloadStarted] = useState(false);
  const [projectName, setProjectName] = useState("");
  const [username, setusername] = useState("");

  const [smallScreen, setSmallScreen] = useState(window.innerWidth <= 760);

  function TransitionLeft(props) {
    return <Slide {...props} direction="left" />;
  }

  const columns = [
    // {
    //   name: "Work Description",
    //   label: "Work Description",
    //   options: {
    //     filter: true,
    //     sort: true,
    //   },
    // },
    {
      name: "Equipment",
      label: "Equipment, Crusher Sale",
      options: {
        filter: true,
        sort: true,
      },
    },
    // {
    //   name: "Work Description",
    //   label: "Work Description",
    //   options: {
    //     filter: true,
    //     sort: true,
    //   },
    // },
    // {
    //   name: "Quantity of Work",
    //   label: "Quantity of Work",
    //   options: {
    //     filter: true,
    //     sort: false,
    //   },
    // },
    {
      name: "Labor, Material & Equipment",
      label: "Labor, Material & Contractor RA",
      options: {
        filter: true,
        sort: true,
      },
    },
  ];
  useEffect(() => {
    let Username = global.config.secureStorage.getItem("username");
    setusername(Username);

    const tempData = [];
    props.dprs.forEach((elem, id) => {
      let row = [];
      let contractorArr = [];
      let equipmentArr = [];
      let materialArr = [];
      let partyDetailsArr = [];
      let laborArr = [];

      let totalequipmentQuantity = 0
      elem.equipment.map((item, index) => {
        if(item.number !='' ){
          if(item.number)
          totalequipmentQuantity=totalequipmentQuantity+parseFloat(item.number)
        }
        equipmentArr.push({ sl: index + 1,total:totalequipmentQuantity, ...item });
      });

      let totalmaterialQuantity = 0
      elem.material.map((item, index) => {

        if(item.number !='' ){
          if(item.number)
          totalmaterialQuantity=totalmaterialQuantity+parseFloat(item.number)
        }
        materialArr.push({ sl: index + 1,total:totalmaterialQuantity, ...item });
      });
      elem.labor.map((item, index) => {
        laborArr.push({ sl: index + 1, ...item });
      });
      
        let totalpartyDetailsQuantity = 0
      elem.partyDetails.map((item, index) => {
        if(item.quantity !='' ){
          if(item.quantity)
          totalpartyDetailsQuantity=totalpartyDetailsQuantity+parseFloat(item.quantity)
        }

        partyDetailsArr.push({ sl: index + 1,total:totalpartyDetailsQuantity, ...item });
      });



      let totalcontractorRAQuantity = 0
      elem.contractorRA.forEach(contractor => {
        contractor.items.forEach((item, i) => {

          if(item.number !='' ){
            if(item.number)
            totalcontractorRAQuantity=totalcontractorRAQuantity+parseFloat(item.number)
          }
          contractorArr.push({
            sl: i + 1,
            item: item.item,
            unit: item.unit,
            quantity: item.number,
            name: contractor.contractorName,
            total:totalcontractorRAQuantity
          });
        });
      });

      row.push(

        <MuiThemeProvider theme={getMuiTheme()}>
               <br />
{equipmentArr.length>0&&(

          <MUIDataTable
            title={`Equipments`}
            data={equipmentArr}
            columns={[
              { name: "sl", label: "SR No" },
              {
                name: "equipment",
                label: "Equipment Name",
                options: {
                  filter: true,
                  sort: true,
                  customHeadRender: ({ index, ...column }) => {
                    return (
                      <TableCell
                        key={index}
                        style={{ fontSize: "14px", paddingleft: "2px" }}
                      >
                        {column.label}
                      </TableCell>
                    );
                  },
                  customBodyRender: (value, tableMeta, updateValue) => {
                    return (
                      <TableCell
                        style={{
                          fontSize: "14px",
                          padding: "0px",
                          border: "none",
                        }}
                      >
                        {value}
                      </TableCell>
                    );
                  },
                },
              },

              {
                name: "diesel",
                label: "Diesel",
                options: {
                  filter: true,
                  sort: true,
                  customHeadRender: ({ index, ...column }) => {
                    return (
                      <TableCell
                        key={index}
                        style={{ fontSize: "14px", paddingleft: "2px" }}
                      >
                        {column.label}
                      </TableCell>
                    );
                  },
                  customBodyRender: (value, tableMeta, updateValue) => {
                    return (
                      <TableCell
                        style={{
                          fontSize: "14px",
                          padding: "0px",
                          border: "none",
                        }}
                      >
                        {value}
                      </TableCell>
                    );
                  },
                },
              },
              {
                name: "start",
                label: "Start",
                options: {
                  filter: true,
                  sort: true,
                  customHeadRender: ({ index, ...column }) => {
                    return (
                      <TableCell
                        key={index}
                        style={{ fontSize: "14px", paddingleft: "2px" }}
                      >
                        {column.label}
                      </TableCell>
                    );
                  },
                  customBodyRender: (value, tableMeta, updateValue) => {
                    return (
                      <TableCell
                        style={{
                          fontSize: "14px",
                          padding: "0px",
                          border: "none",
                        }}
                      >
                        {value}
                      </TableCell>
                    );
                  },
                },
              },
              {
                name: "end",
                label: "End",
                options: {
                  filter: true,
                  sort: true,
                  customHeadRender: ({ index, ...column }) => {
                    return (
                      <TableCell
                        key={index}
                        style={{ fontSize: "14px", paddingleft: "2px" }}
                      >
                        {column.label}
                      </TableCell>
                    );
                  },
                  customBodyRender: (value, tableMeta, updateValue) => {
                    return (
                      <TableCell
                        style={{
                          fontSize: "14px",
                          padding: "0px",
                          border: "none",
                        }}
                      >
                        {value}
                      </TableCell>
                    );
                  },
                },
              },
              {
                name: "countUnit",
                label: "Unit",
                options: {
                  filter: true,
                  sort: true,
                  customHeadRender: ({ index, ...column }) => {
                    return (
                      <TableCell
                        key={index}
                        style={{ fontSize: "14px", paddingleft: "2px" }}
                      >
                        {column.label}
                      </TableCell>
                    );
                  },
                  customBodyRender: (value, tableMeta, updateValue) => {
                    return (
                      <TableCell
                        style={{
                          fontSize: "14px",
                          padding: "0px",
                          border: "none",
                        }}
                      >
                        {value}
                      </TableCell>
                    );
                  },
                },
              },
              {
                name: "totalHours",
                label: "Total",
                options: {
                  filter: true,
                  sort: true,
                  customHeadRender: ({ index, ...column }) => {
                    return (
                      <TableCell
                        key={index}
                        style={{ fontSize: "14px", paddingleft: "2px" }}
                      >
                        {column.label}
                      </TableCell>
                    );
                  },
                  customBodyRender: (value, tableMeta, updateValue) => {
                    return (
                      <TableCell
                        style={{
                          fontSize: "14px",
                          padding: "0px",
                          border: "none",
                        }}
                      >
                        {value}
                      </TableCell>
                    );
                  },
                },
              },
              {
                name: "equipmentMaterial",
                label: "Material",
                options: {
                  filter: true,
                  sort: true,
                  customHeadRender: ({ index, ...column }) => {
                    return (
                      <TableCell
                        key={index}
                        style={{ fontSize: "14px", paddingleft: "2px" }}
                      >
                        {column.label}
                      </TableCell>
                    );
                  },
                  customBodyRender: (value, tableMeta, updateValue) => {
                    return (
                      <TableCell
                        style={{
                          fontSize: "14px",
                          padding: "0px",
                          border: "none",
                        }}
                      >
                        {value}
                      </TableCell>
                    );
                  },
                },
              },

              {
                name: "trip",
                label: "Trip",
                options: {
                  filter: true,
                  sort: true,
                  customHeadRender: ({ index, ...column }) => {
                    return (
                      <TableCell
                        key={index}
                        style={{ fontSize: "14px", paddingleft: "2px" }}
                      >
                        {column.label}
                      </TableCell>
                    );
                  },
                  customBodyRender: (value, tableMeta, updateValue) => {
                    return (
                      <TableCell
                        style={{
                          fontSize: "14px",
                          padding: "0px",
                          border: "none",
                        }}
                      >
                        {value}
                      </TableCell>
                    );
                  },
                },
              },
              {
                name: "unit",
                label: "unit",
                options: {
                  filter: true,
                  sort: true,
                  customHeadRender: ({ index, ...column }) => {
                    return (
                      <TableCell
                        key={index}
                        style={{ fontSize: "14px", paddingleft: "2px" }}
                      >
                        {column.label}
                      </TableCell>
                    );
                  },
                  customBodyRender: (value, tableMeta, updateValue) => {
                    return (
                      <TableCell
                        style={{
                          fontSize: "14px",
                          padding: "0px",
                          border: "none",
                        }}
                      >
                        {value}
                      </TableCell>
                    );
                  },
                },
              },
              {
                name: "number",
                label: "Quantity",
                options: {
                  filter: true,
                  sort: true,
                  customHeadRender: ({ index, ...column }) => {
                    return (
                      <TableCell
                        key={index}
                        style={{ fontSize: "14px", paddingleft: "2px" }}
                      >
                        {column.label}
                      </TableCell>
                    );
                  },
                  customBodyRender: (value, tableMeta, updateValue) => {
                    return (
                      <TableCell
                        style={{
                          fontSize: "14px",
                          padding: "0px",
                          border: "none",
                        }}
                      >
                        {value}
                      </TableCell>
                    );
                  },
                },
              },
            ]}
            className={Style.flexBoxfull}
            options={{
              selectableRows: "none",
              responsive: true,
              pagination: false,
              download: false,
              viewColumns: false,
              filter: true,
              print: false,
              customFooter: (
                ) => {
                  return (
                    <TableFooter>
                      <TableRow>
                        <TableCell variant="h4" colSpan={6} style={{float:'right',border:'none'}}>
                          Total Quantity: {equipmentArr[equipmentArr.length-1].total}
                        </TableCell>
                      </TableRow>
                    </TableFooter>
                  );
                }
            }}
          />
          
          )}


          <br />
          <br />
          <br />
          {elem.metaData.length>0&&(
          <div>
            <Typography
              variant="overline"
              component="h2"
              gutterBottom
              style={{ fontSize: 16, marginLeft: "10px" }}
            >
              Work Done Details:
            </Typography>

            <TableContainer>
              <Table sx={{ minWidth: 650 }} aria-label="simple table">
                <TableHead>
                  <TableRow>
                    <TableCell>Work Done Details</TableCell>
                    <TableCell>Quantity</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {elem.metaData.map(meta => (
                    <TableRow
                      key={row.name}
                      sx={{
                        "&:last-child td, &:last-child th": { border: 0 },
                      }}
                    >
                      <TableCell component="th" scope="row">
                        {meta.description}
                      </TableCell>
                      <TableCell>{meta.quantity}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </div>
          )}
          <br />
          <br />
          {partyDetailsArr.length>0&&(
            <>
          <MUIDataTable
            title={`Crusher Sale`}
            data={partyDetailsArr}
            columns={[
              { name: "sl", label: "SR No" },
              { name: "dc", label: "DC No", 
              
              options: {
                filter: true,
                sort: true,
                customHeadRender: ({ index, ...column }) => {
                  return (
                    <TableCell
                      key={index}
                      style={{ fontSize: "14px", maxWidth: "80px" }}
                    >
                      {column.label}
                    </TableCell>
                  );
                },
                customBodyRender: (value, tableMeta, updateValue) => {
                  return (
                    <TableCell
                      style={{
                        fontSize: "14px",
                        padding: "0px",
                        border: "none", maxWidth: "80px" 
                      }}
                    >
                      {value}
                    </TableCell>
                  );
                },
              },
            
            
            },
              {
                name: "name",
                label: "Party Name",
                options: {
                  filter: true,
                  sort: true,
                  customHeadRender: ({ index, ...column }) => {
                    return (
                      <TableCell
                        key={index}
                        style={{ fontSize: "14px", paddingleft: "2px" }}
                      >
                        {column.label}
                      </TableCell>
                    );
                  },
                  customBodyRender: (value, tableMeta, updateValue) => {
                    return (
                      <TableCell
                        style={{
                          fontSize: "14px",
                          padding: "0px",
                          border: "none",
                        }}
                      >
                        {value}
                      </TableCell>
                    );
                  },
                },
              },

              {
                name: "partyMaterial",
                label: "Material",
                options: {
                  filter: true,
                  sort: true,
                  customHeadRender: ({ index, ...column }) => {
                    return (
                      <TableCell
                        key={index}
                        style={{ fontSize: "14px", paddingleft: "2px" }}
                      >
                        {column.label}
                      </TableCell>
                    );
                  },
                  customBodyRender: (value, tableMeta, updateValue) => {
                    return (
                      <TableCell
                        style={{
                          fontSize: "14px",
                          padding: "0px",
                          border: "none",
                        }}
                      >
                        {value}
                      </TableCell>
                    );
                  },
                },
              },
              {
                name: "unit",
                label: "Unit",
                options: {
                  filter: true,
                  sort: true,
                  customHeadRender: ({ index, ...column }) => {
                    return (
                      <TableCell
                        key={index}
                        style={{ fontSize: "14px", paddingleft: "2px" }}
                      >
                        {column.label}
                      </TableCell>
                    );
                  },
                  customBodyRender: (value, tableMeta, updateValue) => {
                    return (
                      <TableCell
                        style={{
                          fontSize: "14px",
                          padding: "0px",
                          border: "none",
                        }}
                      >
                        {value}
                      </TableCell>
                    );
                  },
                },
              },
              {
                name: "location",
                label: "Location",
                options: {
                  filter: true,
                  sort: true,
                  customHeadRender: ({ index, ...column }) => {
                    return (
                      <TableCell
                        key={index}
                        style={{ fontSize: "14px", paddingleft: "2px" }}
                      >
                        {column.label}
                      </TableCell>
                    );
                  },
                  customBodyRender: (value, tableMeta, updateValue) => {
                    return (
                      <TableCell
                        style={{
                          fontSize: "14px",
                          padding: "0px",
                          border: "none",
                        }}
                      >
                        {value}
                      </TableCell>
                    );
                  },
                },
              },
              {
                name: "remark",
                label: "Remark",
                options: {
                  filter: true,
                  sort: true,
                  customHeadRender: ({ index, ...column }) => {
                    return (
                      <TableCell
                        key={index}
                        style={{ fontSize: "14px", paddingleft: "2px" }}
                      >
                        {column.label}
                      </TableCell>
                    );
                  },
                  customBodyRender: (value, tableMeta, updateValue) => {
                    return (
                      <TableCell
                        style={{
                          fontSize: "14px",
                          padding: "0px",
                          border: "none",
                        }}
                      >
                        {value}
                      </TableCell>
                    );
                  },
                },
              },
              {
                name: "quantity",
                label: "Quantity",
                options: {
                  filter: true,
                  sort: true,
                  customHeadRender: ({ index, ...column }) => {
                    return (
                      <TableCell
                        key={index}
                        style={{ fontSize: "14px", paddingleft: "2px" }}
                      >
                        {column.label}
                      </TableCell>
                    );
                  },
                  customBodyRender: (value, tableMeta, updateValue) => {
                    return (
                      <TableCell
                        style={{
                          fontSize: "14px",
                          padding: "0px",
                          border: "none",
                        }}
                      >
                        {value}
                      </TableCell>
                    );
                  },
                },
              },
            ]}
            className={Style.flexBoxhalf}
            options={{
              selectableRows: "none",
              responsive: true,
              pagination: false,
              download: false,
              viewColumns: false,
              filter: true,
              print: false,
              customFooter: (
              ) => {
                return (
                  <TableFooter>
                    <TableRow>
                      <TableCell variant="h4" colSpan={6} style={{float:'right',border:'none'}}>
                        Total Quantity: {partyDetailsArr[partyDetailsArr.length-1].total}
                      </TableCell>
                    </TableRow>
                  </TableFooter>
                );
              }
            }}
          />
          </>
          )}

          <br />
        </MuiThemeProvider>,

        <div class="container">
          <MuiThemeProvider theme={getMuiTheme()}>
          <br />
          {materialArr.length>0&&(
            <MUIDataTable
              title={`Materials`}
              data={materialArr}
              columns={[
                { name: "sl", label: "SR No" },
                {
                  name: "supplier",
                  label: "Supplier",
                  options: {
                    filter: false,
                    sort: false,
                    customHeadRender: ({ index, ...column }) => {
                      return (
                        <TableCell
                          key={index}
                          style={{ fontSize: "14px", paddingleft: "2px" }}
                        >
                          {column.label}
                        </TableCell>
                      );
                    },
                    customBodyRender: (value, tableMeta, updateValue) => {
                      return (
                        <TableCell
                          style={{
                            fontSize: "14px",
                            padding: "0px",
                            border: "none",
                          }}
                        >
                          {value}
                        </TableCell>
                      );
                    },
                  },
                },
                {
                  name: "material",
                  label: "Material",
                  options: {
                    filter: false,
                    sort: false,
                    customHeadRender: ({ index, ...column }) => {
                      return (
                        <TableCell
                          key={index}
                          style={{ fontSize: "14px", paddingleft: "2px" }}
                        >
                          {column.label}
                        </TableCell>
                      );
                    },
                    customBodyRender: (value, tableMeta, updateValue) => {
                      return (
                        <TableCell
                          style={{
                            fontSize: "14px",
                            padding: "0px",
                            border: "none",
                          }}
                        >
                          {value}
                        </TableCell>
                      );
                    },
                  },
                },
                {
                  name: "unit",
                  label: "Unit",
                  options: {
                    filter: false,
                    sort: false,
                    customHeadRender: ({ index, ...column }) => {
                      return (
                        <TableCell
                          key={index}
                          style={{ fontSize: "14px", paddingleft: "2px" }}
                        >
                          {column.label}
                        </TableCell>
                      );
                    },
                    customBodyRender: (value, tableMeta, updateValue) => {
                      return (
                        <TableCell
                          style={{
                            fontSize: "14px",
                            padding: "0px",
                            border: "none",
                          }}
                        >
                          {value}
                        </TableCell>
                      );
                    },
                  },
                },
                {
                  name: "number",
                  label: "Quantity",
                  options: {
                    filter: false,
                    sort: false,
                    customHeadRender: ({ index, ...column }) => {
                      return (
                        <TableCell
                          key={index}
                          style={{ fontSize: "14px", paddingleft: "2px" }}
                        >
                          {column.label}
                        </TableCell>
                      );
                    },
                    customBodyRender: (value, tableMeta, updateValue) => {
                      return (
                        <TableCell
                          style={{
                            fontSize: "14px",
                            padding: "0px",
                            border: "none",
                          }}
                        >
                          {value}
                        </TableCell>
                      );
                    },
                  },
                },
              ]}
              className={Style.flexBoxhalf}
              options={{
                selectableRows: "none",
                responsive: true,
                pagination: false,
                download: false,
                viewColumns: false,
                filter: true,
                print: false,
                customFooter: (
                  ) => {
                    return (
                      <TableFooter>
                        <TableRow>
                          <TableCell variant="h4" colSpan={6} style={{float:'right',border:'none'}}>
                            Total Quantity: {materialArr[materialArr.length-1].total}
                          </TableCell>
                        </TableRow>
                      </TableFooter>
                    );
                  }
              }}
            />)}
   {laborArr.length>0&&(
            <MUIDataTable
              title={`Labour`}
              data={laborArr}
              columns={[
                { name: "sl", label: "SR No" },
                {
                  name: "labor",
                  label: "Labor name",
                  options: {
                    filter: false,
                    sort: false,
                    customHeadRender: ({ index, ...column }) => {
                      return (
                        <TableCell
                          key={index}
                          style={{ fontSize: "14px", paddingleft: "2px" }}
                        >
                          {column.label}
                        </TableCell>
                      );
                    },
                    customBodyRender: (value, tableMeta, updateValue) => {
                      return (
                        <TableCell
                          style={{
                            fontSize: "14px",
                            padding: "0px",
                            border: "none",
                          }}
                        >
                          {value}
                        </TableCell>
                      );
                    },
                  },
                },
                {
                  name: "unit",
                  label: "Unit",
                  options: {
                    filter: false,
                    sort: false,
                    customHeadRender: ({ index, ...column }) => {
                      return (
                        <TableCell
                          key={index}
                          style={{ fontSize: "14px", paddingleft: "2px" }}
                        >
                          {column.label}
                        </TableCell>
                      );
                    },
                    customBodyRender: (value, tableMeta, updateValue) => {
                      return (
                        <TableCell
                          style={{
                            fontSize: "14px",
                            padding: "0px",
                            border: "none",
                          }}
                        >
                          {value}
                        </TableCell>
                      );
                    },
                  },
                },
                {
                  name: "number",
                  label: "Actual labour",
                  options: {
                    filter: false,
                    sort: false,
                    customHeadRender: ({ index, ...column }) => {
                      return (
                        <TableCell
                          key={index}
                          style={{ fontSize: "14px", paddingleft: "2px" }}
                        >
                          {column.label}
                        </TableCell>
                      );
                    },
                    customBodyRender: (value, tableMeta, updateValue) => {
                      return (
                        <TableCell
                          style={{
                            fontSize: "14px",
                            padding: "0px",
                            border: "none",
                          }}
                        >
                          {value}
                        </TableCell>
                      );
                    },
                  },
                },
                {
                  name: "reqnumber",
                  label: "Required labour",
                  options: {
                    filter: false,
                    sort: false,
                    customHeadRender: ({ index, ...column }) => {
                      return (
                        <TableCell
                          key={index}
                          style={{ fontSize: "14px", paddingleft: "2px" }}
                        >
                          {column.label}
                        </TableCell>
                      );
                    },
                    customBodyRender: (value, tableMeta, updateValue) => {
                      return (
                        <TableCell
                          style={{
                            fontSize: "14px",
                            padding: "0px",
                            border: "none",
                          }}
                        >
                          {value}
                        </TableCell>
                      );
                    },
                  },
                },
                {
                  name: "supplier",
                  label: "Supplier",
                  options: {
                    filter: false,
                    sort: false,
                    customHeadRender: ({ index, ...column }) => {
                      return (
                        <TableCell
                          key={index}
                          style={{ fontSize: "14px", paddingleft: "2px" }}
                        >
                          {column.label}
                        </TableCell>
                      );
                    },
                    customBodyRender: (value, tableMeta, updateValue) => {
                      return (
                        <TableCell
                          style={{
                            fontSize: "14px",
                            padding: "0px",
                            border: "none",
                          }}
                        >
                          {value}
                        </TableCell>
                      );
                    },
                  },
                },
              ]}
              className={Style.flexBoxfull}
              options={{
                selectableRows: "none",
                responsive: true,
                pagination: false,
                download: false,
                viewColumns: false,
                filter: true,
                print: false,
              }}
            />
   )}
            <br />
            {contractorArr.length>0&&(
            <MUIDataTable
              title={`Contractor RA`}
              data={contractorArr}
              columns={[
                { name: "sl", label: "SR No" },
                {
                  name: "name",
                  label: "ContractorName",
                  options: {
                    filter: true,
                    sort: true,
                    customHeadRender: ({ index, ...column }) => {
                      return (
                        <TableCell
                          key={index}
                          style={{ fontSize: "14px", paddingleft: "2px" }}
                        >
                          {column.label}
                        </TableCell>
                      );
                    },
                    customBodyRender: (value, tableMeta, updateValue) => {
                      return (
                        <TableCell
                          style={{
                            fontSize: "14px",
                            padding: "0px",
                            border: "none",
                          }}
                        >
                          {value}
                        </TableCell>
                      );
                    },
                  },
                },
                {
                  name: "item",
                  label: "Item",
                  options: {
                    filter: true,
                    sort: true,
                    customHeadRender: ({ index, ...column }) => {
                      return (
                        <TableCell
                          key={index}
                          style={{ fontSize: "14px", paddingleft: "2px" }}
                        >
                          {column.label}
                        </TableCell>
                      );
                    },
                    customBodyRender: (value, tableMeta, updateValue) => {
                      return (
                        <TableCell
                          style={{
                            fontSize: "14px",
                            padding: "0px",
                            border: "none",
                          }}
                        >
                          {value}
                        </TableCell>
                      );
                    },
                  },
                },
                {
                  name: "unit",
                  label: "Unit",
                  options: {
                    filter: true,
                    sort: true,
                    customHeadRender: ({ index, ...column }) => {
                      return (
                        <TableCell
                          key={index}
                          style={{ fontSize: "14px", paddingleft: "2px" }}
                        >
                          {column.label}
                        </TableCell>
                      );
                    },
                    customBodyRender: (value, tableMeta, updateValue) => {
                      return (
                        <TableCell
                          style={{
                            fontSize: "14px",
                            padding: "0px",
                            border: "none",
                          }}
                        >
                          {value}
                        </TableCell>
                      );
                    },
                  },
                }, {
                  name: "quantity",
                  label: "Quantity",
                  options: {
                    filter: true,
                    sort: true,
                    customHeadRender: ({ index, ...column }) => {
                      return (
                        <TableCell
                          key={index}
                          style={{ fontSize: "14px", paddingleft: "2px" }}
                        >
                          {column.label}
                        </TableCell>
                      );
                    },
                    customBodyRender: (value, tableMeta, updateValue) => {
                      return (
                        <TableCell
                          style={{
                            fontSize: "14px",
                            padding: "0px",
                            border: "none",
                          }}
                        >
                          {value}
                        </TableCell>
                      );
                    },
                  },
                },
              ]}
              className={Style.flexBoxfull}
              options={{
                selectableRows: "none",
                responsive: true,
                pagination: false,
                download: false,
                viewColumns: false,
                filter: true,
                print: false,
                customFooter: (
                  ) => {
                    return (
                      <TableFooter>
                        <TableRow>
                          <TableCell variant="h4" colSpan={6} style={{float:'right',border:'none'}}>
                            Total Quantity: {contractorArr[contractorArr.length-1].total}
                          </TableCell>
                        </TableRow>
                      </TableFooter>
                    );
                  }
              }}
            />
            )}
            <br />
            <>
              <Typography variant="h6">Activity: {elem.activity}</Typography>

              <Grid container style={{ display: "flex" }}>
              {elem.miscellaneous.length>0&&(
                <TableContainer>
                  <Table sx={{ minWidth: 650 }} aria-label="simple table">
                    <TableHead>
                      <TableRow>
                        <TableCell>Miscellaneous cost</TableCell>
                        <TableCell>Reason</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {elem.miscellaneous.map(misc => (
                        <TableRow
                          key={row.name}
                          sx={{
                            "&:last-child td, &:last-child th": { border: 0 },
                          }}
                        >
                          <TableCell component="th" scope="row">
                            {misc.miscellaneous}
                          </TableCell>
                          <TableCell>{misc.reason}</TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              )}

                {elem.remark && elem.remark.trim().length > 0 && (
                  <>
                    <Typography
                      variant="overline"
                      component="h2"
                      gutterBottom
                      style={{ fontSize: 16, marginLeft: "10px" }}
                    >
                      Remarks:
                    </Typography>
                    <Typography
                      variant="overline"
                      component="h4"
                      gutterBottom
                      style={{ fontSize: 14, marginLeft: "10px" }}
                    >
                      {elem.remark}
                    </Typography>
                  </>
                )}
                <Grid>
                  <Grid item container className="mt-4">
                    <Typography
                      variant="overline"
                      component="h4"
                      gutterBottom
                      style={{ fontSize: 14, marginLeft: "10px" }}
                    >
                      generated by: {elem.createdBy}
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
            </>
          </MuiThemeProvider>
        </div>
      );
      tempData.push(row);
    });
    const projectId = global.config.secureStorage.getItem("projectId");
    axios
      .get(`${global.config.backendURL}/project/getProjectInfo/${projectId}`)
      .then(res => setProjectName(res.data.projectName))
      .catch(e => console.log(e));
    setData(tempData);
    setLoading(false);
  }, []); //eslint-disable-line
  const subprojectName = global.config.secureStorage.getItem("subProjectName");
  return (
    <>
      {openDownloadStarted && (
        <Snackbar
          open={openDownloadStarted}
          autoHideDuration={3000}
          anchorOrigin={{ vertical: "top", horizontal: "right" }}
          TransitionComponent={TransitionLeft}
          onClose={() => setOpenDownloadStarted(false)}
        >
          <Alert
            severity="success"
            onClose={() => setOpenDownloadStarted(false)}
            style={{ borderTop: "5px solid green" }}
          >
            <div style={{ color: "green" }}>
              <strong>Download Started</strong>
            </div>
            Check Your Downloads Location!
          </Alert>
        </Snackbar>
      )}

      {!loading && (
        <>
          <Grid item container justify="center" className="mt-4">
            <Button
              variant="outlined"
              startIcon={<KeyboardBackspaceIcon />}
              onClick={props.handleBackButtonClick}
            >
              Go back
            </Button>
          </Grid>
          <center
            style={{
              marginTop: "1.5em",
              display: "flex",
              justifyContent: "center",
            }}
          >
            {/* <Typography variant="overline" style={{ fontSize: "1.2em" }}>
              <strong>Project: </strong>
              {projectName}
            </Typography> */}
            <Typography
              variant="overline"
              style={{ fontSize: "1.2em", marginLeft: "10px" }}
            >
              <strong>Sub Project: </strong>
              {subprojectName}
            </Typography>
            <Typography
              variant="overline"
              component="h1"
              // gutterBottom,
              style={{ fontSize: "1.2em", marginLeft: "10px" }}
            >
              <strong>Date: </strong> {props.clicked}
            </Typography>
          </center>
          <center>
            <button
              className="btn-warning btn mt-3 mb-3 generatePDF"
              onClick={() => {
                // window.print();
                setOpenDownloadStarted(true);
                downloadPDF("DPR Report",'l');
              }}
            >
              Download PDF
            </button>
          </center>

          <div
            style={{ margin: "0 20px" }}
            className="mt-4 mb-4"
            id="download-section"
          >
            <MuiThemeProvider theme={getMuiTheme()}>
              <MUIDataTable
                title={`Project Name:${global.config.secureStorage.getItem("projectName")} | Daily Progress Report for ${props.clicked}`}
                data={data}
                columns={columns}
                options={{
                  selectableRows: "single",
                  responsive: true,
                  pagination: false,
                  download: false,
                  viewColumns: false,
                  filter: false,
                  print: false,
                  onRowsDelete: row_data => {
                    let tempData = data;
                    const ids = row_data.data.map(elem => {
                      tempData.splice(elem.index, 1);
                      return props.dprs[elem.index]._id;
                    });
                    setData(tempData);
                    props.handleDelete(ids);
                    ids.forEach(id => {
                      axios
                        .post(`${global.config.backendURL}/dpr/deleteDpr`, {
                          id: id,
                        })
                        .catch(err => {
                          console.log(err);
                          // setOpenDeleteFailure(true);
                        });
                    });
                  },
                }}
              />
            </MuiThemeProvider>
          </div>
        </>
      )}
    </>
  );
};

export default withRouter(DailyProgressCards);
