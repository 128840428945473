import React, { Component } from "react";
import { connect } from "react-redux";
import {
    getSingleTodo, addRemark, updateTodo
} from "../../Redux/todoRedux/todoAction";
import {
    makeStyles,
    Accordion,
    AccordionSummary,
    AccordionDetails,
    Typography,
    Button,
    Grid,
    Divider,
    useMediaQuery,
    Card,
    CardContent,
} from "@material-ui/core";
import { Add as AddIcon, Edit as EditIcon } from "@material-ui/icons";
import Notifications from "react-notification-system-redux";
import PropTypes from "prop-types";
import Loading from "../../Components/Loading";
import AddRemark from "./AddRemark";
import Style from "./style.module.scss";

import DialogBox from "../../Components/Modal/DialogBox";
class SingleTodo extends Component {
    constructor(props) {
        super(props);
        const role = global.config.secureStorage.getItem("role");
        const editRoles = ["project_manager", "super_admin"];
        var isEditable = editRoles.find(item => item == role) ? true : false;
        const path = this.props.match.params
        this.state = {
            taskTitle: "",
            taskDetail: "",
            userName: "",
            deadline: new Date(),
            todoId: path.todoId.toString(),
            isCreateNewTodo: false,
            isEditable: isEditable,
            todoDetails: '',
            modalOpen: false,
            remarkDescription: '',
            remarkFile: '',
            modelImageUrl: '',
            isModalOpen: false,
            isEdit: false,
            completed: ''
        };
    }



    async componentDidMount() {
        await this.props.getSingleTodo(this.state.todoId);
        // // console.clear();
        if (!this.props.loading) {
            this.setState({
                todoDetails: this.props.todo.data,
                taskTitle: this.props.todo.data.taskTitle,
                taskDetail: this.props.todo.data.taskDetail,
                completed: this.props.todo.data.completed
            });
            //
        }
    }


    handleFile = async file => {
        this.setState({
            remarkFile: file,
        });
    }
    handleShowAddDialog = () => {
        this.setState({
            modalOpen: !this.state.modalOpen
        });
    }
    handleRemarkChange = event => {
        this.setState({
            [event.target.name]: event.target.value,
        });
    }
    submitRemark = () => {
        const change = {
            description: this.state.remarkDescription,
            remarkFile: this.state.remarkFile,
            todoId: this.state.todoId
        };

        this.props.addRemark(this.state.todoDetails._id, change);
        this.handleShowAddDialog()
        this.setState({
            remarkDescription: ''
        });
    }
    updateSingleTodo = () => {
        const change = {
            taskTitle: this.state.taskTitle,
            taskDetail: this.state.taskDetail,
            completed: this.state.completed,
            todoId: this.state.todoId
        };

        this.props.updateTodo(this.state.todoDetails._id, change);
        this.handleShowAddDialog()
        this.setState({
            remarkDescription: ''
        });
    }
    handleShowDialog = url => {
        if (url != '') {
            let ext = url.substr(url.lastIndexOf('.') + 1)
            if (ext == 'jpg' || ext == 'png')
                this.setState({
                    modelImageUrl: url,
                    isModalOpen: !this.state.isModalOpen
                });
            else
                window.open(url, '_blank', 'noopener,noreferrer');
        }

        else
            this.setState({
                modelImageUrl: url,
                isModalOpen: !this.state.isModalOpen
            });
    };

    handleUpdateChange = event => {

        this.setState({
            [event.target.name]: event.target.value,
        });
    };
    setStatus = value => {
        this.setState({
            completed: value === 'Complete',
        });
    };
    render() {
        return (
            <div>
                <DialogBox
                    handleShowDialog={this.handleShowDialog}
                    isModalOpen={this.state.isModalOpen}
                    modelImageUrl={this.state.modelImageUrl}
                />
                <>
                    {this.props.notifications && (
                        <Notifications notifications={this.props.notifications} />
                    )}
                    <div>
                        <AddRemark
                            open={this.state.modalOpen}
                            isEdit={this.state.isEdit}
                            remarkDescription={this.state.remarkDescription}
                            remarkFile={this.state.remarkFile}
                            handleRemarkChange={this.handleRemarkChange}
                            handleShowDialog={this.handleShowAddDialog}
                            handleFile={this.handleFile}
                            submitRemark={this.submitRemark}
                            handleUpdateChange={this.handleUpdateChange}
                            updateSingleTodo={this.updateSingleTodo}
                            taskTitle={this.state.taskTitle}
                            taskDetail={this.state.taskDetail}
                            completed={this.state.completed}
                            setStatus={this.setStatus}
                        />
                    </div>
                    <div className="container">
                        {this.props.loading ? (
                            <Loading loading={this.props.loading} />
                        ) : !this.props.loading && !this.props.todo.data.length ? (
                            <Accordion expanded={true}>
                                <AccordionSummary
                                    style={{ backgroundColor: "#fff" }}
                                    aria-controls="panel1d-content"
                                >
                                    <Grid container>
                                        <Grid item xs={12} lg={10}>
                                            <Typography variant="h5" style={{ marginBottom: '20px' }}>
                                                {this.props.todo.data.taskTitle}{" "}
                                            </Typography>
                                            <Typography variant="body" className={this.props.todo.data.completed ? Style.completed : Style.progress}>
                                                {this.props.todo.data.completed ? "Complete" : "Progress"}
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={12} lg={2} 
                                                style={{ textAlign:'center' }}>
                                            <Button
                                                variant="contained"
                                                color="primary"
                                                onClick={() => {
                                                    this.setState({
                                                        isEdit: true
                                                    });
                                                    this.handleShowAddDialog();
                                                }
                                                }
                                                startIcon={<EditIcon />}
                                            >
                                                Edit
                                            </Button>
                                        </Grid>
                                    </Grid>

                                </AccordionSummary>
                                <AccordionDetails style={{ display: "inline" }}>


                                    <Divider variant="middle" />

                                    <Grid
                                        container
                                        spacing={0}
                                        alignItems="center"
                                        justify="center"
                                        style={{
                                            marginTop: 20,
                                            padding: 20,
                                        }}
                                    >
                                        {this.props.todo.data.isEditable && (
                                            <Button
                                                variant="contained"
                                                color="primary"
                                                onClick={() => {
                                                    this.setState({
                                                        isEdit: false
                                                    });
                                                    this.handleShowAddDialog();
                                                }

                                                }
                                                startIcon={<AddIcon />}
                                            >
                                                Add Remark
                                            </Button>
                                        )}
                                    </Grid>
                                    <Grid
                                        container
                                        justify="center"
                                        alignItems="center"
                                        style={{ marginTop: 60, padding: 20, backgroundColor: "#345BAE" }}
                                    >
                                        <Grid container justify="center" alignItems="center">
                                            <Grid item className="m-4">
                                                <Typography variant="h6" style={{ color: "#fff" }}>
                                                    Todo Remarks:
                                                </Typography>
                                            </Grid>
                                        </Grid>
                                        <Grid container justify="center">
                                            {this.props.todo.data.remark != undefined &&
                                                this.props.todo.data.remark.length > 0
                                                ? this.props.todo.data.remark.map((remark, remarkIndex) => {
                                                    return (
                                                        <Card
                                                            variant="outlined"
                                                            style={{ padding: "5px", width: "100%" }}
                                                            elevation={3}
                                                        >
                                                            <CardContent>
                                                                <Grid container spacing={2}>
                                                                    <Grid
                                                                        item
                                                                        xs={12}
                                                                        md={6}
                                                                        style={{
                                                                            display: "flex",
                                                                            alignItems: "center",
                                                                        }}
                                                                    >
                                                                        <Typography>
                                                                            <strong>Remark by: </strong>
                                                                            {remark.username}
                                                                        </Typography>
                                                                        <Typography>
                                                                            <strong>Remark Description : </strong>
                                                                            {remark.description}
                                                                        </Typography>
                                                                    </Grid>
                                                                    <Grid
                                                                        item
                                                                        xs={12}
                                                                        md={6}
                                                                        style={{
                                                                            display: "flex",
                                                                            justifyContent: "center",
                                                                        }}
                                                                    >
                                                                        <Button
                                                                            variant="contained"
                                                                            color="primary"
                                                                            onClick={() => {

                                                                                this.handleShowDialog(remark.remarkFile);
                                                                            }
                                                                            }
                                                                        >
                                                                            View File
                                                                        </Button>
                                                                    </Grid>
                                                                </Grid>
                                                            </CardContent>
                                                        </Card>
                                                    );
                                                })
                                                : "No Updates"}

                                        </Grid>
                                    </Grid>
                                </AccordionDetails>
                            </Accordion>

                        ) : ''}

                    </div>
                </>
            </div >
        );
    }
}

SingleTodo.propTypes = {
    todo: PropTypes.object.isRequired
};

const mapStateToProps = state => {
    return {
        todo: state.todoReducer,
        notifications: state.notifications,
        success: state.todoReducer.success,
        loading: state.todoReducer.loading
    };
};

export default connect(mapStateToProps, {
    getSingleTodo, addRemark, updateTodo, updateTodo
})(SingleTodo);


