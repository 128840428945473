import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { connect } from "react-redux";
import {
  AppBar,
  Button,
  Toolbar,
  Typography,
  IconButton,
  Grid,
} from "@material-ui/core";
import {
  Menu as MenuIcon,
  Close as CloseIcon,
  ArrowBack as BackButton,
} from "@material-ui/icons";
import Drawer from "./Drawer";
import useStyles from "./NavBarStyle";
import { logout } from "../../Redux/loginRedux/loginAction";
import { getUserProjects } from "../../Redux/projectRedux/projectAction";
import { getSubprojects } from "../../Redux/subProjectRedux/subProjectAction";

import { setupNavTitle } from "./utils";
import { Link } from "react-scroll";
import { Link as RedirectLink } from "react-router-dom";

function ButtonAppBar(props) {
  const [title, setTitle] = useState("Enbuild");
  const classes = useStyles();
  const [drawer, setDrawer] = useState(false);
  const [projectName, setProjectName] = useState("");
  const [subprojects, setSubprojects] = useState([]);
  const [subprojectName, setSubprojectName] = useState("");
  const [isLogout, setLogout] = useState(false);
  const [isBack, setIsBack] = useState(false);
  const [isDrawerVisible, setDrawerVisible] = useState(false);
  const [drawerInvisibleRoutes] = useState([
    "/projectList",
    "/subprojectList",
    "/login",
    "/register",
    "/registerAdmin",
    "/addProject",
    "/dashboard",
  ]);
  const [logoutInVisibleRoutes] = useState([
    "/login",
    "/register",
    "/registerAdmin",
  ]);
  const [isAdmin, _] = useState(
    global.config.secureStorage.getItem("is_admin")
  );
  const his = useHistory();
  // const [anchorEl, setAnchorEl] = React.useState(null);
  const toggleDrawer = event => {
    if (
      event &&
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }
    setDrawer(prevState => !prevState);
  };

  const logout = () => {
    // global.config.secureStorage.clear();
    props.logout();
    his.push("/login");
    // setLogoutState(true);
  };
  let handleProjectName = async e => {
    setProjectName(e.target.value.projectName);
    // console.log(e.target.value._id);
    await props.getSubprojects(e.target.value._id);
  };
  let handleSubProjectName = e => {
    setSubprojectName(e.target.value);
  };

  // let changeCreds = () => {
  //   // global.config.secureStorage.setItem("projectId", this.props.project._id);
  //   console.log(projectName + "changed");
  //   global.config.secureStorage.setItem("projectName", projectName);
  // };

  his.listen((location, action) => {
    //
    if (drawerInvisibleRoutes.find(item => item === location.pathname)) {
      setDrawerVisible(false);
    } else {
      setDrawerVisible(true);
    }
    if (logoutInVisibleRoutes.find(item => item === location.pathname)) {
      setLogout(false);
    } else {
      setLogout(true);
    }
    setupNavTitle(his.location.pathname, setTitle, setIsBack);
  });
  useEffect(async () => {
    await props.getUserProjects(
      global.config.secureStorage.getItem("username")
    );
  }, []);

  useEffect(() => {
    if (drawerInvisibleRoutes.find(item => item === his.location.pathname)) {
      setDrawerVisible(false);
    } else {
      setDrawerVisible(true);
    }
    if (logoutInVisibleRoutes.find(item => item === his.location.pathname)) {
      setLogout(false);
    } else {
      setLogout(true);
    }
    setupNavTitle(his.location.pathname, setTitle, setIsBack);
  }, [his.location.pathname, logoutInVisibleRoutes, drawerInvisibleRoutes]);

  let Showlogo = window.innerWidth > 400 ? true : false;

  return (
    <div className={classes.root}>
      <AppBar position="fixed" className={classes.appBar}>
        <Toolbar>


          {his.location.pathname !== "/" ? (
            <>
              {!isBack ? (
                <>
                  {isDrawerVisible && (
                    <IconButton
                      edge="start"
                      className={classes.menuButton}
                      color="inherit"
                      aria-label="menu"
                      onClick={toggleDrawer}
                    >
                      {!drawer && <MenuIcon />}
                      {drawer && <CloseIcon />}
                    </IconButton>
                  )}
                </>
              ) : (
                <>
                  <IconButton
                    edge="start"
                    className={classes.menuButton}
                    color="inherit"
                    aria-label="menu"
                    onClick={his.goBack}
                  >
                    <BackButton />
                  </IconButton>
                </>
              )}
            </>
          ) : (
            <div className={classes.homepageIcon}>
              {isDrawerVisible && (
                <IconButton
                  edge="start"
                  className={classes.menuButton}
                  color="inherit"
                  aria-label="menu"
                  onClick={toggleDrawer}
                >
                  {!drawer && <MenuIcon />}
                  {drawer && <CloseIcon />}
                </IconButton>
              )}
            </div>
          )}

          {Showlogo ? (
            <Typography
              variant="h5"
              className={classes.menuButton}
              style={{ marginRight: 20 }}
            >
              Enbuild
            </Typography>
          ) : (
            ""
          )}

          <Typography variant="h6" className={classes.title}>
            {/* {his.location.pathname === "/" || title === "Enbuild"   ? (
              <RedirectLink
                style={{
                  textDecoration: "none",
                  color: "white",
                  fontFamily: "Bungee Inline",
                  fontSize: "1.25em",
                }}
                to="/"
              >
                Enbuild
              </RedirectLink>
            ) : (
              title
            )} */}
            {his.location.pathname === "/" && Showlogo ? "" : title}
          </Typography>

          {his.location.pathname === "/" && (
            <Grid
              container
              justify="flex-end"
              spacing={4}
              style={{ marginRight: 10 }}
              className={classes.navGrid}
            >
              <Grid item>
                <Link
                  to="about"
                  spy={true}
                  smooth={true}
                  style={{ cursor: "pointer" }}
                >
                  <Typography color="primary">
                    <strong>ABOUT US</strong>
                  </Typography>
                </Link>
              </Grid>
              <Grid item>
                <Link
                  to="problems"
                  spy={true}
                  smooth={true}
                  style={{ cursor: "pointer" }}
                >
                  <Typography color="primary">
                    <strong>PROBLEMS FACED</strong>
                  </Typography>
                </Link>
              </Grid>
              <Grid item>
                <Link
                  to="objective"
                  spy={true}
                  smooth={true}
                  style={{ cursor: "pointer" }}
                >
                  <Typography color="primary">
                    <strong>OUR SOLUTION</strong>
                  </Typography>
                </Link>
              </Grid>
              <Grid item>
                <Link
                  to="timeline"
                  spy={true}
                  smooth={true}
                  style={{ cursor: "pointer" }}
                >
                  <Typography color="primary">
                    <strong>TIMELINE</strong>
                  </Typography>
                </Link>
              </Grid>
              <Grid item>
                <Link
                  to="services"
                  spy={true}
                  smooth={true}
                  style={{ cursor: "pointer" }}
                >
                  <Typography color="primary">
                    <strong>FEATURES AND SERVICES</strong>
                  </Typography>
                </Link>
              </Grid>
              <Grid item>
                <Link
                  to="demo"
                  spy={true}
                  smooth={true}
                  style={{ cursor: "pointer" }}
                >
                  <Typography color="primary">
                    <strong>DEMO</strong>
                  </Typography>
                </Link>
              </Grid>
              <Grid item>
                <Link
                  to="contact"
                  spy={true}
                  smooth={true}
                  style={{ cursor: "pointer" }}
                >
                  <Typography color="primary">
                    <strong>CONTACT US</strong>
                  </Typography>
                </Link>
              </Grid>
            </Grid>
          )}
          {isLogout &&
            (his.location.pathname === "/" ? props.loggedIn : true) && ( // Hide logout button only when on homepage
              <Button
                variant="outlined"
                style={{ color: "#2E86AB", marginRight: "10px" }}
                onClick={logout}
              >
                Logout
              </Button>
            )}
        </Toolbar>

      </AppBar>

      <Toolbar />
      {
        isDrawerVisible && (
          <Drawer
            isOpen={drawer}
            children={props.children}
            toggleDrawer={toggleDrawer}
            userProjects={props.userProjects}
            subprojects={props.subProjects}
            projectName={projectName}
            subprojectName={subprojectName}
            handleProjectName={handleProjectName}
            handleSubProjectName={handleSubProjectName}
          // changeCreds={changeCreds}
          ></Drawer>
        )
      }

    </div >

  );
}

const mapStateToProps = state => {
  return {
    userProjects: state.projectReducer.userProjects,
    loggedIn: state.loginReducer.loggedIn,
    subProjects: state.subProjectReducer.subProjects,
  };
};

// export default connect(mapStateToProps, mapDispatchToProps)(ClippedDrawer);
export default connect(mapStateToProps, {
  logout,
  getUserProjects,
  getSubprojects,
})(ButtonAppBar);
