import "date-fns";
import React, { useState, useEffect } from "react";
import {
  Typography,
  Grid,
  Button,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  TableContainer,
  Table,
  TableRow,
  TableCell,
  TableHead,
  TableBody,
  Divider,
} from "@material-ui/core";
import { Save, Cancel } from "@material-ui/icons";

import Loading from "../../Components/Loading";
import { ArrowBack, FilterList, CloudDownload } from "@material-ui/icons";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers";
import axios from "axios";
import Style from "./style.module.scss";

const ActivityReport = props => {
  const [users, setUsers] = useState([]);
  const [open, setOpen] = useState(false);

  return (
    <div className="container">
      <Grid container className={Style.header___top}>
        <Typography
          variant="h4"
          color="textSecondary"
          style={{ marginLeft: "0.5em", color: "#fff" }}
        >
          Enbuild
        </Typography>
      </Grid>
      <Grid container className={Style.container___info}>
        <Grid item sm={12} xs={12} justify="center" style={{ display: "flex" }}>
          <Typography variant="h6" style={{ marginLeft: "0.5em" }}>
            Project Name :
          </Typography>
          <Typography
            variant="h6"
            color="textSecondary"
            style={{ marginLeft: "0.5em" }}
          >
            {global.config.secureStorage.getItem("projectName")}
          </Typography>
        </Grid>
        <Grid item xs={4}>
          <Typography
            variant="h6"
            color="textSecondary"
            style={{ marginLeft: "0.5em" }}
          >
            Generated By
          </Typography>
          <Typography variant="h6" style={{ marginLeft: "0.5em" }}>
            {global.config.secureStorage.getItem("user_fullname")}
          </Typography>
        </Grid>
        <Grid item xs={4}>
          <Typography
            variant="h6"
            color="textSecondary"
            style={{ marginLeft: "0.5em" }}
          >
            Project Start Date
          </Typography>
          <Typography variant="h6" style={{ marginLeft: "0.5em" }}>
            NA
          </Typography>
        </Grid>
        <Grid item xs={4}>
          <Typography
            variant="h6"
            color="textSecondary"
            style={{ marginLeft: "0.5em" }}
          >
            Generated On
          </Typography>
          <Typography variant="h6" style={{ marginLeft: "0.5em" }}>
            {new Date(new Date()).toISOString().split("T")[0]}
          </Typography>
        </Grid>
      </Grid>

      <Grid container className={Style.container___info}>
        <Grid item xs={12}>
          <Typography variant="h6" style={{ marginLeft: "0.5em" }}>
            Activity Report
          </Typography>
          <Typography
            variant="p"
            color="textSecondary"
            style={{ marginLeft: "0.5em" }}
          >
            {/* This Report is from {props.startDate} to {props.endDate} */}
          </Typography>
        </Grid>
      </Grid>
      <hr />

      <div className={Style.table___box}>
        {props.newWbs.map((elem, id) => {
          if (elem !== null) {
            return (
              <>
                <Grid
                  container
                  style={{
                    padding: "20px",
                    display: "flex",
                    justifyContent: "space-between",
                  }}
                >
                  <Grid
                    sm={12}
                    style={{
                      display: "flex",
                      flexWrap: "wrap",
                      marginBottom: "10px",
                    }}
                  >
                    <Typography variant="h6">Activity:{" - "}</Typography>
                    <Typography variant="h6">
                      <span>{elem.activity}</span>
                    </Typography>
                  </Grid>
                  <div>
                    <Typography variant="p">
                      Estimated Start Date:{" - "}{" "}
                      {elem.dates.EStart
                        ? new Date(elem.dates.EStart)
                            .toISOString()
                            .split("T")[0]
                        : "--"}
                    </Typography>
                    <br />
                    <Typography variant="p">
                      Estimated End Date:{" - "}
                      {elem.dates.EEnd
                        ? new Date(elem.dates.EEnd).toISOString().split("T")[0]
                        : "--"}
                    </Typography>
                  </div>
                  <div>
                    <Typography variant="p">
                      Actual Start Date:{" - "}{" "}
                      {elem.dates.AStart
                        ? new Date(elem.dates.AStart)
                            .toISOString()
                            .split("T")[0]
                        : "--"}
                    </Typography>
                    <br />
                    <Typography variant="p">
                      Actual End Date:{" - "}
                      {elem.dates.AEnd
                        ? new Date(elem.dates.AEnd).toISOString().split("T")[0]
                        : "--"}
                    </Typography>
                  </div>
                </Grid>

                <Grid>
                  {elem.data.map((subAcc, id2) => {
                    return subAcc !== null ? (
                      <Accordion key={id} defaultExpanded={true}>
                        <AccordionSummary>
                          <Typography variant="h6">
                            Sub Activity:{" - "} {subAcc.link}
                          </Typography>
                        </AccordionSummary>

                        <AccordionDetails className={Style.table___detail}>
                          <TableContainer>
                            <Table size="small">
                              <TableHead>
                                <TableRow>
                                  <TableCell>Task Name</TableCell>
                                  <TableCell>Estimate Start Date</TableCell>
                                  <TableCell>Estimate End Date</TableCell>
                                  <TableCell>Actual Start Date</TableCell>
                                  <TableCell>Actual End Date</TableCell>
                                  <TableCell width="10%">Progress</TableCell>
                                </TableRow>
                              </TableHead>
                              <TableBody>
                                {subAcc.Tasks
                                  ? subAcc.Tasks.map((taskInfo, id3) =>
                                      taskInfo != null ? (
                                        <React.Fragment>
                                          <TableRow key={id3}>
                                            <TableCell>
                                              <Grid
                                                item
                                                xs={12}
                                                style={{ marginTop: "10px" }}
                                              >
                                                {taskInfo.name &&
                                                taskInfo.name != ""
                                                  ? taskInfo.name
                                                  : "--"}
                                              </Grid>
                                              <Grid
                                                item
                                                xs={12}
                                                style={{ marginTop: "10px" }}
                                              >
                                                {taskInfo.taskPer >= 100
                                                  ? "[Completed]"
                                                  : "[In Progress]"}
                                              </Grid>
                                            </TableCell>

                                            <TableCell>
                                              <Grid
                                                item
                                                xs={12}
                                                style={{ marginTop: "10px" }}
                                              >
                                                <span variant="h6">
                                                  {" "}
                                                  {taskInfo.estart &&
                                                  taskInfo.estart != ""
                                                    ? new Date(taskInfo.estart)
                                                        .toISOString()
                                                        .split("T")[0]
                                                    : "--"}
                                                </span>
                                              </Grid>
                                            </TableCell>
                                            <TableCell>
                                              <Grid
                                                item
                                                xs={12}
                                                style={{ marginTop: "10px" }}
                                              >
                                                <span variant="h6">
                                                  {" "}
                                                  {taskInfo.eend &&
                                                  taskInfo.eend != ""
                                                    ? new Date(taskInfo.eend)
                                                        .toISOString()
                                                        .split("T")[0]
                                                    : "--"}
                                                </span>
                                              </Grid>
                                            </TableCell>
                                            <TableCell>
                                              <Grid
                                                item
                                                xs={12}
                                                style={{ marginTop: "10px" }}
                                              >
                                                <span variant="h6">
                                                  {" "}
                                                  {taskInfo.astart &&
                                                  taskInfo.astart != ""
                                                    ? new Date(taskInfo.astart)
                                                        .toISOString()
                                                        .split("T")[0]
                                                    : "--"}
                                                </span>
                                              </Grid>
                                            </TableCell>
                                            <TableCell>
                                              <Grid
                                                item
                                                xs={12}
                                                style={{ marginTop: "10px" }}
                                              >
                                                <span variant="h6">
                                                  {" "}
                                                  {taskInfo.aend &&
                                                  taskInfo.aend != ""
                                                    ? new Date(taskInfo.aend)
                                                        .toISOString()
                                                        .split("T")[0]
                                                    : "--"}
                                                </span>
                                              </Grid>
                                            </TableCell>
                                            <TableCell>
                                              <Grid
                                                item
                                                xs={12}
                                                style={{ marginTop: "10px" }}
                                              >
                                                <span variant="h6">
                                                  {" "}
                                                  {taskInfo.taskPer &&
                                                  taskInfo.taskPer != ""
                                                    ? taskInfo.taskPer.toFixed(
                                                        2
                                                      ) + "%"
                                                    : "0%"}
                                                </span>
                                                <br />
                                                <span
                                                  variant="p"
                                                  style={{ color: "#345BAE" }}
                                                >
                                                  {"{"}{" "}
                                                  {taskInfo.taskPerCumulative &&
                                                  taskInfo.taskPerCumulative !=
                                                    ""
                                                    ? taskInfo.taskPerCumulative.toFixed(
                                                        2
                                                      ) + "%"
                                                    : "--"}{" "}
                                                  {"}"}
                                                </span>
                                              </Grid>
                                            </TableCell>
                                          </TableRow>

                                          <Grid
                                            style={{
                                              paddingTop: "20px",
                                              display: "flex",
                                              marginLeft: "100px",
                                            }}
                                          >
                                            <Typography
                                              variant="h6"
                                              justify="center"
                                            >
                                              Sub Tasks
                                            </Typography>
                                          </Grid>
                                          <Table
                                            style={{
                                              width: "100%",
                                              marginLeft: "100px",
                                            }}
                                            aria-label="simple table"
                                          >
                                            <TableHead>
                                              <TableRow>
                                                <TableCell>
                                                  Sub Task Name
                                                </TableCell>
                                                <TableCell>
                                                  Percentage
                                                </TableCell>
                                              </TableRow>
                                            </TableHead>
                                            <TableBody
                                              style={{ width: "100%" }}
                                            >
                                              {taskInfo.Subtask.map(row => (
                                                <TableRow
                                                  key={row.name}
                                                  sx={{
                                                    "&:last-child td, &:last-child th":
                                                      { border: 0 },
                                                  }}
                                                >
                                                  <TableCell
                                                    component="th"
                                                    scope="row"
                                                  >
                                                    {row.name}
                                                  </TableCell>
                                                  <TableCell
                                                    component="th"
                                                    scope="row"
                                                  >
                                                    {row.per}%
                                                  </TableCell>
                                                </TableRow>
                                              ))}
                                            </TableBody>
                                          </Table>

                                          <br />
                                        </React.Fragment>
                                      ) : (
                                        ""
                                      )
                                    )
                                  : ""}
                              </TableBody>
                            </Table>
                          </TableContainer>
                        </AccordionDetails>
                      </Accordion>
                    ) : (
                      ""
                    );
                  })}
                </Grid>
              </>
            );
          }
        })}
      </div>
    </div>
  );
};
export default ActivityReport;
