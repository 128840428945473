import React, { Component } from "react";
import { AddCircleOutline, Delete } from "@material-ui/icons";
import { Grid, TextField, Button, IconButton } from "@material-ui/core";
import _ from "lodash";
import Notifications from "react-notification-system-redux";
// import { addMaterialList } from "../../Redux/projectRedux/projectAction";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import Papa from "papaparse";
import { CSVLink } from "react-csv";
import DisplayContractorRA from "./DisplayContractorRA";
import {
  addContractorRAList,
  getMasterListContractorRA,
} from "../../../Redux/storeRedux/storeAction";
import { trusted } from "mongoose";
// import DisplayManPower from "../DisplayManPower";
// import DisplayLabour from "../DisplayLabour";
// import DisplayEquipment from "../DisplayEquipment";

class AddMaterialList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      materialCount: 1,
      itemCount: 1,
      items: [],
      materials: [],
      itemName: "",
      exportdata: [],
      store: [],
      errorindex: [],
      itemErrorIndex: [],
      contractorError:"",
      itemErrorText:"",
            // isAdded: false,
    };
  }
  handleExport = () => {
    const newExportData = [];
    //    let counter=1;
    //    newExportData.push({
    //     "sr.no":counter,
    //      "contractorname":item.contractorName,
    //      "unit":item.contractorUnit,
    //      "rate":item.contractorRate,
    //      "item":item.contractorItem,
    //      "pancard_no":item.contractorPancardNo,
    //      "GST_no":item.contractorGSTNo
    // })
    // counter++;
    console.log("props contractorra", this.props.contractorRAData);
    this.props.contractorRAData.forEach((contractor, index) => {
      newExportData.push({
        "sr.no": index + 1,
        contractorname: contractor.contractorName,
        pancard_no: contractor.contractorPancardNo,
        GST_no: contractor.contractorGSTNo,
      });
      contractor.items.forEach((item, i) => {
        newExportData.push({
          "sr.no": index + 1 + "." + (i + 1),
          item: item.item,
          unit: item.unit,
          rate: item.rate,
        });
      });
    });
    console.log("new export is", newExportData);
    this.setState({
      exportdata: newExportData,
    });
  };
  handleExportFile = () => {
    this.setState({
      exportdata: [],
    });
  };
  changeHandler = event => {
    Papa.parse(event.target.files[0], {
      header: true,
      skipEmptyLines: true,
      complete: function (results) {
        const rowsArray = [];
        const valuesArray = [];

        results.data.map(d => {
          rowsArray.push(Object.keys(d));
          valuesArray.push(Object.values(d));
        });

        const newImportedItem = [];
        let curr_contractor = {};
        let curr_items = [];
        valuesArray.forEach((row, rowNo) => {
          let itemNo = 0;
          let startOfSrno = row[0][0];
          for (let k = 0; k < row[0].length - 1; k++) {
            if (row[0][k] === "." && row[0][k] !== row[0][k + 1]) {
              if (startOfSrno === ".") startOfSrno = row[0][k + 1];
              else itemNo++;
            }
          }
          console.log("itemNo", itemNo);
          console.log("contractorName.", curr_contractor.contractorName);
          if (itemNo === 0) {
            if (curr_contractor.contractorName === undefined) {
              curr_contractor = {
                contractorName: row[1],
                contractorPancardNo: row[2],
                contractorGSTNo: row[3],
                items: [],
              };
            } else {
              curr_contractor.items = curr_items;
              newImportedItem.push(curr_contractor);
              curr_contractor = {
                contractorName: row[1],
                contractorPancardNo: row[2],
                contractorGSTNo: row[3],
                items: [],
              };
              curr_items = [];
            }
          } else if (itemNo === 1) {
            curr_items.push({
              item: row[4],
              unit: row[5],
              rate: row[6],
            });
          }
        });
        console.log("outside loop", curr_contractor, curr_items);
        if (curr_contractor.contractorName) {
          curr_contractor.items = curr_items;
          newImportedItem.push(curr_contractor);
          curr_contractor = {};
          curr_items = {};
        }
        console.log("new imported items", newImportedItem);
        this.props.addContractorRAList(newImportedItem);
        window.location.reload();
      }.bind(this),
    });
  };
  headers = [
    { label: "Sr no", key: "sr.no" },
    { label: "Contractor", key: "contractorname" },
    { label: "Pancard_no", key: "pancard_no" },
    { label: "GST_no", key: "GST_no" },
    { label: "ITEM", key: "item" },
    { label: "UNIT", key: "unit" },
    { label: "RATE", key: "rate" },
  ];
  async componentDidMount() {
    //this.state.materials[i].items.length
    await this.props.getMasterListContractorRA();
    let new_contractor = [
      {
        contractorName: "",
        contractorPancardNo: "",
        contractorGSTNo: "",
        items: [
          {
            item: "",
            rate: "",
            unit: "",
          },
        ],
      },
    ];
    let temp_error=[[false]]
    this.setState({
      itemErrorIndex:temp_error
    })
    this.setState({
      store: this.props.contractorRADataData,
      materials: new_contractor,
    });

    console.log("items elngthing", this.state.materials[0].items.length);
  }
  refreshPage = () => {
    window.location.reload(false);
  };

  deleteMaterial = i => {
    if (this.state.materialCount === 1) {
      alert(`atleast 1 ${this.state.itemName} is required`);
      return;
    }
    var arr = [...this.state.materials];
    var temp_itemErrorIndex=[...this.state.itemErrorIndex]
     temp_itemErrorIndex.splice(i,1);
     let con_errorIndex=this.state.errorindex
     con_errorIndex.splice(i,1)
    arr.splice(i, 1);
    this.setState({
      materials: arr,
      materialCount: this.state.materialCount - 1,
      errorindex:con_errorIndex,
      itemErrorIndex:temp_itemErrorIndex
    });
  };
  deleteItem = (i, contractorIndex) => {
    console.log("materials are here", this.state.materials);
    if (this.state.materials[contractorIndex].items.length === 1) {
      alert("atleast 1 item is required");
      return;
    }
    var arr = this.state.materials[contractorIndex].items;
    var temp_itemErrorIndex=[...this.state.itemErrorIndex]
    var curr_itemIndex=temp_itemErrorIndex[contractorIndex]
    curr_itemIndex.splice(i,1);
    arr.splice(i, 1);
    temp_itemErrorIndex[contractorIndex]=curr_itemIndex
    let con_errorIndex=this.state.errorindex
    con_errorIndex.splice(contractorIndex,1)
    var new_arr = this.state.materials;
    new_arr[contractorIndex].items = arr;
    this.setState({
      materials: new_arr,
      itemErrorIndex:temp_itemErrorIndex,
      errorindex:con_errorIndex,
      itemCount: this.state.itemCount - 1,
    });
    console.log("this .sate mateiral are", this.state.materials);
  };

  addMaterial = async (event, index) => {
    const prev = this.state.materials;
    
    let found = false;

    this.props.contractorRAData.forEach((material, i) => {
      if (material.contractorName.trim() === event.target.value.trim()) {
        found = true;
        return;
      }
    });
    this.state.materials.forEach((material, i) => {
      if (
        material.contractorName.trim() === event.target.value.trim() &&
        material.contractorName !== "" &&
        i !== index
      ) {
        found = true;
        return;
      }
    });

    let err = this.state.errorindex;
    if (found) {
      err[index] = true;
      this.setState({ 
        errorindex: err,
        contractorError:"please enter valid contractor name"
      });
      //return;
    }else if(event.target.value.trim().length === 0){
      err[index] = true;
      this.setState({ 
        errorindex: err,
        contractorError:`please enter valid contractor name`
      });
    } else {
      err[index] = false;
      this.setState({ errorindex: err });
    }
    let sameNamearr=[]
    this.state.materials.forEach( (con,i) =>{
      if(con.contractorName.trim() === this.state.materials[index].contractorName.trim() && index !=i )
      {
        sameNamearr.push(i);
      }
    })
    console.log("sameNameerror",sameNamearr)
    if(sameNamearr.length >1)
    {
      sameNamearr=[]
    }
    if(sameNamearr.length ===1)
    {
      console.log("checked this ")
      let temp_errorindex=[...this.state.errorindex]
      temp_errorindex[sameNamearr[0]]=false
      console.log("tjhis is",temp_errorindex)
      this.setState({
        errorindex:temp_errorindex
      })
    }
    prev[index] = {
      ...prev[index],
      contractorName: event.target.value,
    };

    this.setState({
      materials: prev,
    });
  };
  setItemUnits = (event, c_index, item_index) => {
    let prev = this.state.materials;
    prev[c_index].items[item_index].unit = event.target.value.trim();
    this.setState({
      materials: prev,
    });
  };
  setItemRate = (event, c_index, item_index) => {
    let prev = this.state.materials;
    prev[c_index].items[item_index].rate = event.target.value.trim();
    this.setState({
      materials: prev,
    });
    console.log("this chaed", this.state.materials);
  };

  setContractorItem = (event, c_index, item_index) => {
    let prev = this.state.materials;
    let error_found = false;
    prev[c_index].items.forEach((item,index) => {
      if (item.item.trim() === event.target.value.trim() && index !== item_index) {
        error_found = true;
        return;
      }
    });
    if (error_found && event.target.value.trim().length !== 0) {
      let item_errorindex = [...this.state.itemErrorIndex];
      console.log("item index index ",item_index[c_index])
      item_errorindex[c_index][item_index] = true;
      console.log("item error indexi is ",item_errorindex)
      this.setState({
        itemErrorIndex:item_errorindex,
        itemErrorText:"item name already exists"
      })
    }else if(event.target.value.trim().length === 0){
      let item_errorindex = [...this.state.itemErrorIndex];
      item_errorindex[c_index][item_index] = true;
      this.setState({
        itemErrorIndex:item_errorindex,
        itemErrorText:"please fill this field"
      })
    } else {
      let item_errorindex = [...this.state.itemErrorIndex];
      item_errorindex[c_index][item_index] = false;
      this.setState({
        itemErrorIndex:item_errorindex
      })
    }
    let sameNamearr=[]
    this.state.materials[c_index].items.forEach( (it,i) =>{
      if(it.item.trim() === this.state.materials[c_index].items[item_index].item.trim() && item_index !=i )
      {
        sameNamearr.push(i);
      }
    })
    if(sameNamearr.length >1)
    {
      sameNamearr=[]
    }
    if(sameNamearr.length ===1)
    {
      let temp_errorindex=[...this.state.itemErrorIndex]
      temp_errorindex[c_index][sameNamearr[0]]=false
      console.log("if three are thier ",temp_errorindex)
      this.setState({
        itemErrorIndex:temp_errorindex
      })
    }
    prev[c_index].items[item_index].item = event.target.value;
    this.setState({
      materials: prev,
    });
  };
  setContractorPan = (event, index) => {
    const prev = this.state.materials;
    prev[index].contractorPancardNo = event.target.value.trim();
    this.setState({
      materals: prev,
    });
  };
  setContractorGST = (event, index) => {
    const prev = this.state.materials;
    prev[index].contractorGSTNo = event.target.value.trim();
    this.setState({
      materals: prev,
    });
  };
  //   Check once if it works
  setMaterialCount = () => {
    let new_contractor = {
      contractorName: "",
      contractorPancardNo: "",
      contractorGSTNo: "",
      items: [
        {
          item: "",
          rate: "",
          unit: "",
        },
      ],
    };
    let prev = this.state.materials;
    let temp_itemErrorIndex=this.state.itemErrorIndex
    temp_itemErrorIndex[this.state.materialCount]=[false]
    prev.push(new_contractor);
    this.setState({
      materialCount: this.state.materialCount + 1,
      materials: prev,
    });
  };
  setItemCount = cindex => {
    this.setState({
      itemCount: this.state.itemCount + 1,
    });
    let new_item = {
      item: "",
      rate: "",
      unit: "",
    };
    let temp_itemErrorIndex=this.state.itemErrorIndex
    temp_itemErrorIndex[cindex]=[false]

    let new_contractorlist = this.state.materials;
    new_contractorlist[cindex].items.push(new_item);
    this.setState({
      materials: new_contractorlist,
      itemErrorIndex:temp_itemErrorIndex
    });
  };
  onSubmit = e => {
    e.preventDefault();
    console.log("materiaal are", this.state.materials);
    // return;
    //   e.preventDefault();
    let errorfound = false;
    this.state.errorindex.forEach(val => {
      if (val) {
        errorfound = true;
        alert(`please enter valid Contractor name`);

        return;
      }
    });
    this.state.itemErrorIndex.forEach((val,index) =>{
       val.forEach(v=>{
        if(v){
          console.log("this is it tester ",index)
       errorfound=true;
       alert("please enter valid item name")
     }
       })
    })
    if (errorfound) return;
    let prevs=this.state.materials
    prevs.forEach(prev_con =>{
      prev_con.contractorName=prev_con.contractorName.trim()
      prev_con.items.forEach(it =>{
        it.item= it.item.trim()
      })
    })
    this.setState({
      materials:prevs
    })
    const currmasterList = this.state.materials;
    let new_contractor = [
      {
        contractorName: "",
        contractorPancardNo: "",
        contractorGSTNo: "",
        items: [
          {
            item: "",
            rate: "",
            unit: "",
          },
        ],
      },
    ];
    this.setState({
      materialCount: 1,
      materials: new_contractor,
      // isAdded: true,
    });

    console.log("currmasterlist", currmasterList);
    this.props.addContractorRAList(currmasterList);
    e.target.reset();
    //    this.refreshPage();
  };
  render() {
    return (
      <div>
        {this.props.notifications && (
          <Notifications notifications={this.props.notifications} />
        )}
        {/* {this.state.isAdded ? this.refreshPage() : null} */}

        <div className="container mt-2" style={{ marginBottom: "1em" }}>
          <form onSubmit={this.onSubmit}>
            <Grid container spacing={2} style={{ padding: "10px" }}>
              {/* <h3 className="mt-5 mb-4">Add Materials</h3> */}
              {_.times(this.state.materialCount, i => (
                <Grid
                  container
                  style={{ padding: "10px", marginTop: "10px" }}
                  direction="row"
                  key={i}
                  spacing={2}
                >
                  <Grid item xs={12} sm={4}>
                    <TextField
                      name={`${this.state.itemName}Name`}
                      variant="outlined"
                      error={
                        this.state.errorindex[i] &&
                        this.state.contractorError
                      }
                      helperText={
                        this.state.errorindex[i] &&
                        this.state.contractorError
                      }
                      required
                      fullWidth
                      id="itemName"
                      value={
                        this.state.materials[i] &&
                        this.state.materials[i].contractorName
                      }
                      onChange={e => this.addMaterial(e, i)}
                      label={`Contractor name ${i + 1}`}
                      autoFocus
                    />
                  </Grid>
                  <br />
                  <br />
                  <Grid item xs={12} sm={3}>
                    <TextField
                      name={`pancard no `}
                      variant="outlined"
                      //  className="m-1"
                      required
                      fullWidth
                      type="text"
                      id={`pancard`}
                      value={
                        this.state.materials[i] &&
                        this.state.materials[i].contractorPancardNo
                      }
                      onChange={e => this.setContractorPan(e, i)}
                      label={`pancard no ${i + 1}`}
                      autoFocus
                    />
                  </Grid>

                  <Grid item xs={12} sm={3}>
                    <TextField
                      name={`GST no `}
                      variant="outlined"
                      //  className="m-1"
                      required
                      fullWidth
                      type="text"
                      id={`GST`}
                      value={
                        this.state.materials[i] &&
                        this.state.materials[i].contractorGSTNo
                      }
                      onChange={e => this.setContractorGST(e, i)}
                      label={`GST no ${i + 1}`}
                      autoFocus
                    />
                  </Grid>
                  <Grid>
                    {this.state.materials[i] &&
                      this.state.materials[i].items &&
                      _.times(this.state.materials[i].items.length, index => (
                        <div key={index}>
                          <Grid container spacing={2} direction="row">
                            <Grid item xs={12} sm={3}>
                              <TextField
                                name={`item`}
                                variant="outlined"
                                //  className="m-1"
                                required
                                fullWidth
                                error={this.state.itemErrorIndex[i] &&
                                  this.state.itemErrorIndex[i][index] &&
                                  this.state.itemErrorText
                                }
                                helperText={this.state.itemErrorIndex[i] &&
                                  this.state.itemErrorIndex[i][index] &&
                                  this.state.itemErrorText
                                }
                                type="text"
                                id={`item`}
                                value={
                                  this.state.materials[i].items[index].item
                                }
                                onChange={e =>
                                  this.setContractorItem(e, i, index)
                                }
                                label={`item ${index + 1}`}
                                autoFocus
                              />
                            </Grid>
                            <Grid item xs={12} sm={3}>
                              <TextField
                                name={`${this.state.itemName} unit`}
                                variant="outlined"
                                // className="m-1"
                                required
                                fullWidth
                                id="itemunit"
                                value={
                                  this.state.materials[i].items[index].unit
                                }
                                onChange={e => this.setItemUnits(e, i, index)}
                                label={`unit ${index + 1}`}
                                autoFocus
                              />
                            </Grid>
                            <br />
                            <br />
                            <Grid item xs={12} sm={3}>
                              <TextField
                                name={`${this.state.itemName} rate`}
                                variant="outlined"
                                //  className="m-1"
                                required
                                fullWidth
                                type="number"
                                id={`${this.state.itemName}rate`}
                                value={
                                  this.state.materials[i].items[index].rate
                                }
                                onChange={e => this.setItemRate(e, i, index)}
                                label={`rate ${index + 1}`}
                                autoFocus
                              />
                            </Grid>

                            <Grid item xs={12} sm={2}>
                              <IconButton
                                color="secondary"
                                onClick={() => this.deleteItem(index, i)}
                              >
                                <Delete />
                              </IconButton>
                            </Grid>
                          </Grid>
                        </div>
                      ))}
                  </Grid>
                  <Grid item style={{ marginLeft: "2px" }}>
                    <Button
                      onClick={() => this.setItemCount(i)}
                      variant="outlined"
                      startIcon={<AddCircleOutline />}
                    >
                      Add New Item
                    </Button>
                  </Grid>
                  <Grid item xs={12} sm={2}>
                    <IconButton
                      color="secondary"
                      onClick={() => this.deleteMaterial(i)}
                    >
                      <Delete />
                    </IconButton>
                  </Grid>
                </Grid>
              ))}
              <Grid item style={{ marginLeft: "2px" }}>
                <Button
                  onClick={() => this.setMaterialCount()}
                  variant="outlined"
                  startIcon={<AddCircleOutline />}
                >
                  Add New {this.state.itemName}
                </Button>
              </Grid>
              <Grid item style={{ marginLeft: "4px" }}>
                <Button
                  variant="contained"
                  color="primary"
                  type="submit"
                  style={{ margin: "0px 5px" }}
                >
                  Submit
                </Button>
              </Grid>
            </Grid>
          </form>

          <Grid container direction="row" style={{ marginTop: "2em" }}>
            <Button className="m-2" variant="contained" component="label">
              <input
                type="file"
                name="file"
                onChange={this.changeHandler}
                accept=".csv"
                hidden
              />
              Import ContractorRA
            </Button>

            <Button className="m-2" color="primary" onClick={this.handleExport}>
              Export ContractorRA
            </Button>
            {this.state && this.state.exportdata.length > 0 && (
              <Button className="m-2" color="primary">
                <CSVLink
                  style={{ color: "white" }}
                  data={this.state.exportdata}
                  headers={this.headers}
                  onClick={this.handleExportFile}
                >
                  Download file
                </CSVLink>
              </Button>
            )}
          </Grid>
        </div>
        <hr />
        {/* <DisplayLabour /> */}
        <DisplayContractorRA />
      </div>
    );
  }
}
AddMaterialList.propTypes = {
  materials: PropTypes.object.isRequired,
};
const mapStateToProps = state => {
  return {
    materials: state.storeReducer,
    notifications: state.notifications,
    addMaterialsLoading: state.storeReducer.materialListLoading,
    addMaterialsFailure: state.storeReducer.materialListFailure,
    addMaterialsSuccess: state.storeReducer.materialListSuccess,
    storeData: state.storeReducer.storeData,
    equipmentData: state.storeReducer.equipmentData,
    contractorRAData: state.storeReducer.contractorRAData,
    manpowerData: state.storeReducer.manpowerData,
  };
};
export default connect(mapStateToProps, {
  getMasterListContractorRA,
  addContractorRAList,
})(AddMaterialList);
