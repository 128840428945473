// import "date-fns";
// import React, { useState, useEffect } from "react";
// import {
//   Typography,
//   Grid,
//   Button,
//   Table,
//   TableRow,
//   TableCell,
//   TableFooter
// } from "@material-ui/core";
// import Style from "../style.module.scss";
// import MUIDataTable from "mui-datatables";

// const ActivityReport = props => {
//   const [users, setUsers] = useState([]);
//   const [open, setOpen] = useState(false);
//   const [startDate, setStartDate] = useState(props.startDate);
//   const [endDate, setEndDate] = useState(props.endDate);
//   const [smallScreen, setSmallScreen] = useState(window.innerWidth <= 760);

//   const [filteredList, setFilteredList] = useState(props.newWbs);

//   let rTotal = 0
//   let tTotal = 0
//   let dTotal = 0
//   let eTotal = 0

//   function findQuatity() {
//     let totalequipmentRending = 0
//     let totalequipmentTrip = 0
//     let totalequipmentDiesel = 0
//     let totalequipmentQuantity = 0
//     filteredList.map((item, index) => {
//       if (item.totalHours != '') {
//         if (item.totalHours)
//           totalequipmentRending = parseFloat(totalequipmentRending) + parseFloat(item.totalHours)
//       }
//       if (item.trip != '') {
//         if (item.trip)
//           totalequipmentTrip = parseFloat(totalequipmentTrip) + parseFloat(item.trip)
//       }
//       if (item.diesel != '') {
//         if (item.diesel)
//           totalequipmentDiesel = parseFloat(totalequipmentDiesel) + parseFloat(item.diesel)
//       }
//       if (item.number != '') {
//         if (item.number)
//           totalequipmentQuantity = parseFloat(totalequipmentQuantity) + parseFloat(item.number)
//       }
//       rTotal = totalequipmentRending
//       tTotal = totalequipmentTrip
//       dTotal = totalequipmentDiesel
//       eTotal = totalequipmentQuantity
//     });

//   }

//   findQuatity()
//   let columns = []
//   if (props.DprReportFormat == "Equipment Wise") {
//     console.log('props.newWbs',props)
//     columns = [
//       {
//         name: "equipment",
//         label: "Equipment",
//         options: {
//           filter: true,
//           sort: true,
//         },
//       },
//       {
//         name: "unit",
//         label: "unit",
//         options: {
//           filter: true,
//           sort: true,
//         },
//       },
//       {
//         name: "date",
//         label: "Date",
//         options: {
//           filter: true,
//           sort: true,
//         },
//       },

//       {
//         name: "equipmentMaterial",
//         label: "Material",
//         options: {
//           filter: true,
//           sort: true,
//         },
//       },
//       {
//         name: "start",
//         label: "Start Time",
//         options: {
//           filter: true,
//           sort: true,
//         },
//       },
//       {
//         name: "end",
//         label: "End Time",
//         options: {
//           filter: true,
//           sort: true,
//         },
//       },

//       {
//         name: "remark",
//         label: "Remark",
//         options: {
//           filter: true,
//           sort: true,
//         },
//       },
//       {
//         name: "totalHours",
//         label: "Total",
//         options: {
//           filter: true,
//           sort: true,
//         },
//       },
//       {
//         name: "trip",
//         label: "Trip",
//         options: {
//           filter: true,
//           sort: true,
//         },
//       },
//       {
//         name: "diesel",
//         label: "Diesel",
//         options: {
//           customHeadRender: ({ index, ...column }) => {
//             return (
//               <TableCell key={index} style={{ width: '8px' }}>
//                 {column.label}
//               </TableCell>
//             );
//           },
//           filter: true,
//           sort: true,
//         },
//       },
//       {
//         name: "number",
//         label: "Quantity",
//         options: {
//           filter: true,
//           sort: true,
//         },
//       },
//     ]
//   }
//   else if (props.DprReportFormat == 'Material Wise') {
//     columns = [
//       {
//         name: "equipmentMaterial",
//         label: "Material",
//         options: {
//           filter: true,
//           sort: true,
//         },
//       },
//       {
//         name: "equipment",
//         label: "Equipment",
//         options: {
//           filter: true,
//           sort: true,
//         },
//       },
//       {
//         name: "unit",
//         label: "unit",
//         options: {
//           filter: true,
//           sort: true,
//         },
//       },

//       {
//         name: "date",
//         label: "Date",
//         options: {
//           filter: true,
//           sort: true,
//         },
//       },
//       {
//         name: "start",
//         label: "Start Time",
//         options: {
//           filter: true,
//           sort: true,
//         },
//       },
//       {
//         name: "end",
//         label: "End Time",
//         options: {
//           filter: true,
//           sort: true,
//         },
//       },

//       {
//         name: "remark",
//         label: "Remark",
//         options: {
//           filter: true,
//           sort: true,
//         },
//       },
//       {
//         name: "totalHours",
//         label: "Total",
//         options: {
//           filter: true,
//           sort: true,
//         },
//       },
//       {
//         name: "trip",
//         label: "Trip",
//         options: {
//           filter: true,
//           sort: true,
//         },
//       },
//       {
//         name: "diesel",
//         label: "Diesel",
//         options: {
//           customHeadRender: ({ index, ...column }) => {
//             return (
//               <TableCell key={index} style={{ width: '8px' }}>
//                 {column.label}
//               </TableCell>
//             );
//           },
//           filter: true,
//           sort: true,
//         },
//       },

//       {
//         name: "number",
//         label: "Quantity",
//         options: {
//           filter: true,
//           sort: true,
//         },
//       },
//     ]
//   }
//   else if (props.DprReportFormat == 'Date Wise') {
//     columns = [
//       {
//         name: "date",
//         label: "Date",
//         options: {
//           customHeadRender: ({ index, ...column }) => {
//             return (
//               <TableCell key={index} style={{ width: '8px' }}>
//                 {column.label}
//               </TableCell>
//             );
//           },
//           filter: true,
//           sort: true,
//         },
//       },
//       {
//         name: "equipment",
//         label: "Equipment",
//         options: {
//           customHeadRender: ({ index, ...column }) => {
//             return (
//               <TableCell key={index} style={{ width: '8px' }}>
//                 {column.label}
//               </TableCell>
//             );
//           },
//           filter: true,
//           sort: true,
//         },
//       },

//       {
//         name: "unit",
//         label: "unit",
//         options: {
//           customHeadRender: ({ index, ...column }) => {
//             return (
//               <TableCell key={index} style={{ width: '8px' }}>
//                 {column.label}
//               </TableCell>
//             );
//           },
//           filter: true,
//           sort: true,
//         },
//       },

//       {
//         name: "equipmentMaterial",
//         label: "Material",
//         options: {
//           customHeadRender: ({ index, ...column }) => {
//             return (
//               <TableCell key={index} style={{ width: '8px' }}>
//                 {column.label}
//               </TableCell>
//             );
//           },
//           filter: true,
//           sort: true,
//         },
//       },

//       {
//         name: "start",
//         label: "Start",
//         options: {
//           customHeadRender: ({ index, ...column }) => {
//             return (
//               <TableCell key={index} style={{ width: '8px' }}>
//                 {column.label}
//               </TableCell>
//             );
//           },
//           filter: true,
//           sort: true,
//         },
//       },
//       {
//         name: "end",
//         label: "End",
//         options: {
//           customHeadRender: ({ index, ...column }) => {
//             return (
//               <TableCell key={index} style={{ width: '8px' }}>
//                 {column.label}
//               </TableCell>
//             );
//           },
//           filter: true,
//           sort: true,
//         },
//       },
//       {
//         name: "remark",
//         label: "Remark",
//         options: {
//           filter: true,
//           sort: true,
//         },
//       },
//       {
//         name: "totalHours",
//         label: "Total",
//         options: {
//           customHeadRender: ({ index, ...column }) => {
//             return (
//               <TableCell key={index} style={{ width: '8px' }}>
//                 {column.label}
//               </TableCell>
//             );
//           },
//           filter: true,
//           sort: true,
//         },
//       },
//       {
//         name: "trip",
//         label: "Trip",
//         options: {
//           customHeadRender: ({ index, ...column }) => {
//             return (
//               <TableCell key={index} style={{ width: '8px' }}>
//                 {column.label}
//               </TableCell>
//             );
//           },
//           filter: true,
//           sort: true,
//         },
//       },
//       {
//         name: "diesel",
//         label: "Diesel",
//         options: {
//           customHeadRender: ({ index, ...column }) => {
//             return (
//               <TableCell key={index} style={{ width: '8px' }}>
//                 {column.label}
//               </TableCell>
//             );
//           },
//           filter: true,
//           sort: true,
//         },
//       },
//       {
//         name: "number",
//         label: "Quantity",
//         options: {
//           customHeadRender: ({ index, ...column }) => {
//             return (
//               <TableCell key={index} style={{ width: '8px' }}>
//                 {column.label}
//               </TableCell>
//             );
//           },
//           filter: true,
//           sort: true,
//         },
//       },
//     ]
//   };


//   return (
//     <>


//       <Grid container className={Style.container___info}>
//         <Grid item xs={12}>
//           <Typography
//             variant="h6"
//             style={{ marginLeft: "0.5em" }}
//           >
//             Project Name:{props.projectName}
//           </Typography>
//         </Grid>
//       </Grid>


//       <div className={Style.table___box}>
//         {props.newWbs !== undefined && (
//           <MUIDataTable
//             title={`Equipments`}
//             data={props.newWbs}
//             columns={columns}
//             options={{
//               selectableRows: "none",
//               responsive: smallScreen ? "scroll" : 'simple',
//               pagination: false,
//               download: false,
//               viewColumns: false,
//               filter: true,
//               print: false,
//               customTableBodyFooterRender: (
//                 count,
//                 page,
//                 rowsPerPage,
//                 changeRowsPerPage,
//                 changePage
//                 ) => {
//                   return (
//                     <TableFooter>
//                       <TableRow>
//                       <TableCell variant="h4">
//                         Total
//                         </TableCell>
//                       <TableCell variant="h4">
//                         </TableCell>
//                       <TableCell variant="h4">
//                         </TableCell>
//                       <TableCell variant="h4">
//                         </TableCell>
//                       <TableCell variant="h4">
//                         </TableCell>
//                       <TableCell variant="h4">
//                         </TableCell>
//                       <TableCell variant="h4">
//                         </TableCell>
//                         <TableCell variant="h4">
//                          {rTotal.toFixed(2)}
//                         </TableCell>
//                         <TableCell variant="h4">
//                           {tTotal.toFixed(2)}
//                         </TableCell>
//                         <TableCell variant="h4">
//                            {dTotal.toFixed(2)}
//                         </TableCell>
//                         <TableCell variant="h4">
//                           {eTotal.toFixed(2)}
//                         </TableCell>
//                       </TableRow>
//                     </TableFooter>
//                   );
//                 },

//               onFilterChange: (changedColumn,filterList,type,changedColumnIndex,displayData)=>{
//                 eTotal = 0
//                 dTotal = 0
//                 tTotal = 0
//                 rTotal = 0
//                 let templist= []
                
//                 displayData.forEach(ele => {
//                   console.log(ele.data)
//                   templist.push({number:ele.data[10],totalHours:ele.data[7],diesel:ele.data[9],trip:ele.data[8]})
//                 });
//                 findQuatity()
//                 setFilteredList(templist)
//               }
//             }}
//           />
//         )}
//       </div>
//     </>
//   );
// };
// export default ActivityReport;


import "date-fns";
import React, { useState, useEffect } from "react";
import {
  Typography,
  Grid,
  Button,
  Table,
  TableRow,
  TableCell,
  TableFooter
} from "@material-ui/core";
import Style from "../style.module.scss";
import MUIDataTable from "mui-datatables";
import MisHeader from "../MisHeader";
import Loading from "../../../../Components/Loading";

const ActivityReport = props => {
  const [currentPage, setCurrentPage] = useState(1);
  const [loading, setLoading] = useState(false);
  const [pageLoading, setPageLoading] = useState(props.loading);
  const rowsPerPage = 10;
  const [displayedProjects, setDisplayedProjects] = useState([]);

useEffect(() => {
    setDisplayedProjects(props.projects.slice(0, rowsPerPage));
  }, [props.projects]);

  useEffect(()=>{
    setPageLoading(props.loading)
  }, [props.loading])

const loadMore = () => {
    if (loading) return;
    setLoading(true);

    setTimeout(() => {
      const nextData = props.projects.slice(
        currentPage * rowsPerPage,
        (currentPage + 1) * rowsPerPage
      );
      setDisplayedProjects((prev) => [...prev, ...nextData]);
      setCurrentPage((prev) => prev + 1);
      setLoading(false);
    }, 1000);
  };


    function findQuantity(filteredList) {
    let totals = {
      rTotal: 0,
      tTotal: 0,
      dTotal: 0,
      eTotal: 0,
    };

    filteredList.forEach((item) => {
      totals.rTotal += parseFloat(item.totalHours || 0);
      totals.tTotal += parseFloat(item.trip || 0);
      totals.dTotal += parseFloat(item.diesel || 0);
      totals.eTotal += parseFloat(item.number || 0);
    });

    return totals;
  }


  let columns = []
  if (props.DprReportFormat == "Equipment Wise") {
    console.log('props.newWbs',props)
    columns = [
      {
        name: "equipment",
        label: "Equipment",
        options: {
          filter: true,
          sort: true,
        },
      },
      {
        name: "unit",
        label: "unit",
        options: {
          filter: true,
          sort: true,
        },
      },
      {
        name: "date",
        label: "Date",
        options: {
          filter: true,
          sort: true,
        },
      },

      {
        name: "equipmentMaterial",
        label: "Material",
        options: {
          filter: true,
          sort: true,
        },
      },
      {
        name: "start",
        label: "Start Time",
        options: {
          filter: true,
          sort: true,
        },
      },
      {
        name: "end",
        label: "End Time",
        options: {
          filter: true,
          sort: true,
        },
      },

      {
        name: "remark",
        label: "Remark",
        options: {
          filter: true,
          sort: true,
        },
      },
      {
        name: "totalHours",
        label: "Total",
        options: {
          filter: true,
          sort: true,
        },
      },
      {
        name: "trip",
        label: "Trip",
        options: {
          filter: true,
          sort: true,
        },
      },
      {
        name: "diesel",
        label: "Diesel",
        options: {
          customHeadRender: ({ index, ...column }) => {
            return (
              <TableCell key={index} style={{ width: '8px' }}>
                {column.label}
              </TableCell>
            );
          },
          filter: true,
          sort: true,
        },
      },
      {
        name: "number",
        label: "Quantity",
        options: {
          filter: true,
          sort: true,
        },
      },
    ]
  }
  else if (props.DprReportFormat == 'Material Wise') {
    columns = [
      {
        name: "equipmentMaterial",
        label: "Material",
        options: {
          filter: true,
          sort: true,
        },
      },
      {
        name: "equipment",
        label: "Equipment",
        options: {
          filter: true,
          sort: true,
        },
      },
      {
        name: "unit",
        label: "unit",
        options: {
          filter: true,
          sort: true,
        },
      },

      {
        name: "date",
        label: "Date",
        options: {
          filter: true,
          sort: true,
        },
      },
      {
        name: "start",
        label: "Start Time",
        options: {
          filter: true,
          sort: true,
        },
      },
      {
        name: "end",
        label: "End Time",
        options: {
          filter: true,
          sort: true,
        },
      },

      {
        name: "remark",
        label: "Remark",
        options: {
          filter: true,
          sort: true,
        },
      },
      {
        name: "totalHours",
        label: "Total",
        options: {
          filter: true,
          sort: true,
        },
      },
      {
        name: "trip",
        label: "Trip",
        options: {
          filter: true,
          sort: true,
        },
      },
      {
        name: "diesel",
        label: "Diesel",
        options: {
          customHeadRender: ({ index, ...column }) => {
            return (
              <TableCell key={index} style={{ width: '8px' }}>
                {column.label}
              </TableCell>
            );
          },
          filter: true,
          sort: true,
        },
      },

      {
        name: "number",
        label: "Quantity",
        options: {
          filter: true,
          sort: true,
        },
      },
    ]
  }
  else if (props.DprReportFormat == 'Date Wise') {
    columns = [
      {
        name: "date",
        label: "Date",
        options: {
          customHeadRender: ({ index, ...column }) => {
            return (
              <TableCell key={index} style={{ width: '8px' }}>
                {column.label}
              </TableCell>
            );
          },
          filter: true,
          sort: true,
        },
      },
      {
        name: "equipment",
        label: "Equipment",
        options: {
          customHeadRender: ({ index, ...column }) => {
            return (
              <TableCell key={index} style={{ width: '8px' }}>
                {column.label}
              </TableCell>
            );
          },
          filter: true,
          sort: true,
        },
      },

      {
        name: "unit",
        label: "unit",
        options: {
          customHeadRender: ({ index, ...column }) => {
            return (
              <TableCell key={index} style={{ width: '8px' }}>
                {column.label}
              </TableCell>
            );
          },
          filter: true,
          sort: true,
        },
      },

      {
        name: "equipmentMaterial",
        label: "Material",
        options: {
          customHeadRender: ({ index, ...column }) => {
            return (
              <TableCell key={index} style={{ width: '8px' }}>
                {column.label}
              </TableCell>
            );
          },
          filter: true,
          sort: true,
        },
      },

      {
        name: "start",
        label: "Start",
        options: {
          customHeadRender: ({ index, ...column }) => {
            return (
              <TableCell key={index} style={{ width: '8px' }}>
                {column.label}
              </TableCell>
            );
          },
          filter: true,
          sort: true,
        },
      },
      {
        name: "end",
        label: "End",
        options: {
          customHeadRender: ({ index, ...column }) => {
            return (
              <TableCell key={index} style={{ width: '8px' }}>
                {column.label}
              </TableCell>
            );
          },
          filter: true,
          sort: true,
        },
      },
      {
        name: "remark",
        label: "Remark",
        options: {
          filter: true,
          sort: true,
        },
      },
      {
        name: "totalHours",
        label: "Total",
        options: {
          customHeadRender: ({ index, ...column }) => {
            return (
              <TableCell key={index} style={{ width: '8px' }}>
                {column.label}
              </TableCell>
            );
          },
          filter: true,
          sort: true,
        },
      },
      {
        name: "trip",
        label: "Trip",
        options: {
          customHeadRender: ({ index, ...column }) => {
            return (
              <TableCell key={index} style={{ width: '8px' }}>
                {column.label}
              </TableCell>
            );
          },
          filter: true,
          sort: true,
        },
      },
      {
        name: "diesel",
        label: "Diesel",
        options: {
          customHeadRender: ({ index, ...column }) => {
            return (
              <TableCell key={index} style={{ width: '8px' }}>
                {column.label}
              </TableCell>
            );
          },
          filter: true,
          sort: true,
        },
      },
      {
        name: "number",
        label: "Quantity",
        options: {
          customHeadRender: ({ index, ...column }) => {
            return (
              <TableCell key={index} style={{ width: '8px' }}>
                {column.label}
              </TableCell>
            );
          },
          filter: true,
          sort: true,
        },
      },
    ]
  };


  return (
    <>
      {pageLoading ? (
        <Loading loading={true} />
      ) : props.projects.length > 0 ? (
        <div>
          <MisHeader
            startDate={props.startDate}
            endDate={props.endDate}
          />
          {displayedProjects.map((project, index) => {
            const { projectName, data } = project;
            const totals = findQuantity(data);

            return (
              <div key={index}>
                <Grid container className={Style.container___info}>
                  <Grid item xs={12}>
                    <Typography variant="h6" style={{ marginLeft: "0.5em" }}>
                      Project Name: {projectName}
                    </Typography>
                  </Grid>
                </Grid>

                <div className={Style.table___box}>
                  {data && (
                    <MUIDataTable
                      title={`Equipment Report for ${projectName}`}
                      data={data}
                      columns={columns}
                      options={{
                        selectableRows: "none",
                        responsive: window.innerWidth <= 760 ? "scroll" : "simple",
                        pagination: false,
                        download: false,
                        viewColumns: false,
                        filter: true,
                        print: false,
                        customTableBodyFooterRender: () => (
                          <TableFooter>
                            <TableRow>
                              <TableCell>Total</TableCell>
                              <TableCell />
                              <TableCell />
                              <TableCell />
                              <TableCell />
                              <TableCell />
                              <TableCell />
                              <TableCell>{totals.rTotal.toFixed(2)}</TableCell>
                              <TableCell>{totals.tTotal.toFixed(2)}</TableCell>
                              <TableCell>{totals.dTotal.toFixed(2)}</TableCell>
                              <TableCell>{totals.eTotal.toFixed(2)}</TableCell>
                            </TableRow>
                          </TableFooter>
                        ),
                      }}
                    />
                  )}
                </div>
              </div>
            );
          })}

          {currentPage * rowsPerPage < props.projects.length && !loading && (
            <div style={{ textAlign: "center", marginTop: "1em" }}>
              <Button
                variant="contained"
                color="primary"
                onClick={loadMore}
                disabled={loading}
              >
                {loading ? "Loading..." : "Load More"}
              </Button>
            </div>
          )}

        </div>
      ) : (
          <Typography
            variant="h4"
            color="textSecondary"
            style={{ marginTop: "2em", textAlign: "center" }}
          >
            Select Filter To Generate Report!!
          </Typography>
      )}
    </>
  );
};
export default ActivityReport;
