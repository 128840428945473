import "date-fns";
import React, { useState, useEffect } from "react";
import {
  Typography,
  Grid,
  Button,
  Table,
  TableRow,
  TableCell,
  TableHead,
  TableBody,
  TableContainer,TableFooter
} from "@material-ui/core";
import Style from "../style.module.scss";
import MUIDataTable from "mui-datatables";
import { createMuiTheme, MuiThemeProvider } from "@material-ui/core/styles";

import getMuiTheme from "../misTheme";
const LaboursReport = props => {
  const [data, setData] = useState([]);
  const [users, setUsers] = useState([]);
  const [open, setOpen] = useState(false);
  const [startDate, setStartDate] = useState(props.startDate);
  const [endDate, setEndDate] = useState(props.endDate);
  const [smallScreen, setSmallScreen] = useState(window.innerWidth <= 760);

  
  const [totalQuantity, setTotalQuantity] = useState([]);
  let columns = [];
  if (props.ReportFormat == "Labours Wise") {
    columns = [
      {
        name: "mat",
        label: "Labour Name",
        options: {
          filter: true,
          sort: true,
        },
      },
      {
        name: "masadt",
        label: "Values",
        options: {
          filter: true,
          sort: true,
        },
      },
    ];
  } else if (props.ReportFormat == "Suppiler Wise") {
    columns = [
      {
        name: "mat",
        label: "Supplier Name",
        options: {
          filter: true,
          sort: true,
        },
      },
      {
        name: "masadt",
        label: "Values",
        options: {
          filter: true,
          sort: true,
        },
      },
    ];
  }

  const tempQualityRes = (arr,elemIndex)=>{
    let totalQuantitytemp = [...totalQuantity]
    totalQuantitytemp[elemIndex]=0
    arr.map((item, index) => {
      if(item.number !='' ){
        if(item.number)
        totalQuantitytemp[elemIndex]=parseFloat(totalQuantitytemp[elemIndex])+parseFloat(item.number)
      }
      item.serial = index + 1;
    });
    return totalQuantitytemp;
  }
  useEffect(() => {
    const tempData = [];
    props.newWbs.forEach((elem, elemIndex) => {
      let totalQuantitytemp = tempQualityRes(elem.elm,elemIndex);
      let row = [];
      if (props.ReportFormat == "Labours Wise") {
 
        row.push(
          elem.mat,
          <div>
            <MuiThemeProvider theme={getMuiTheme()}>


              <MUIDataTable
                data={elem.elm}
                columns={[ {
                  name: "serial",
                  label: "Serial No"
                 },{
                  name: "date",
                  label: "Date",
                },
                {
                  name: "supplier",
                  label: "Supplier",
                },
                {
                  name: "number",
                  label: "Quanity",
                },
                {
                  name: "unit",
                  label: "unit",
                },
                ]
                }
                options={{
                  selectableRows: "none",
                   responsive: smallScreen ? "scroll" : "simple",
                  pagination: false,
                  download: false,
                  viewColumns: false,
                  filter: true,
                  print: false,
                  setTableProps: () => ({className: "tableInside"}),
                  customFooter: (
                    ) => {
                      return (
                        <TableFooter>
                          <TableRow>
                            <TableCell variant="h4" colSpan={6} style={{float:'right',border:'none'}}>
                            Total Quantity:{totalQuantity[elemIndex] ? totalQuantity[elemIndex].toFixed(2):totalQuantitytemp[elemIndex].toFixed(2)}
                            </TableCell>
                          </TableRow>
                        </TableFooter>
                      );
                    },
                    onFilterChange: (changedColumn,filterList,type,changedColumnIndex,displayData)=>{
                      let templist= []
                      displayData.forEach(ele1 => {
                        templist.push({number:ele1.data[3]})
                      });

                      let totalequipmentQuantity = tempQualityRes(templist,elemIndex);

                      setTotalQuantity(totalequipmentQuantity)
                    }
                }}
              />



            </MuiThemeProvider>
          </div>
        );
      } else if (props.ReportFormat == "Suppiler Wise") {
        let totalQuantitytemp = tempQualityRes(elem.elm,elemIndex);

        row.push(
          elem.mat,
          <div>
            <MuiThemeProvider theme={getMuiTheme()}>
              <MUIDataTable
                data={elem.elm}
                columns={[ {
                  name: "serial",
                  label: "Serial No"
                 },{
                  name: "date",
                  label: "Date",
                },
                {
                  name: "labor",
                  label: "Labor",
                },
                {
                  name: "number",
                  label: "Quanity",
                },
                {
                  name: "unit",
                  label: "unit",
                },
                ]
                }
                options={{
                  selectableRows: "none",
                   responsive: smallScreen ? "scroll" : "simple",
                  pagination: false,
                  download: false,
                  viewColumns: false,
                  filter: true,
                  print: false,
                  setTableProps: () => ({className: "tableInside"}),
                  customFooter: (
                    ) => {
                      return (
                        <TableFooter>
                          <TableRow>
                            <TableCell variant="h4" colSpan={6} style={{float:'right',border:'none'}}>
                            Total Quantity:{totalQuantity[elemIndex] ? totalQuantity[elemIndex].toFixed(2):totalQuantitytemp[elemIndex].toFixed(2)}
                            </TableCell>
                          </TableRow>
                        </TableFooter>
                      );
                    },
                    onFilterChange: (changedColumn,filterList,type,changedColumnIndex,displayData)=>{
                      let templist= []
                      displayData.forEach(ele1 => {
                        templist.push({number:ele1.data[3]})
                      });

                     
                      let totalequipmentQuantity = tempQualityRes(templist,elemIndex);
                      setTotalQuantity(totalequipmentQuantity)
                    }
                }}
              />

            </MuiThemeProvider>
          </div>
        );
      }

      tempData.push(row);
    });

    setData(tempData);
  }, [totalQuantity]);

  return (
    <>
      <div className="container" id="download-section">
        <Grid container className={Style.header___top}>
          <Typography
            variant="h4"
            color="textSecondary"
            style={{ marginLeft: "0.5em", color: "#fff" }}
          >
            Enbuild
          </Typography>
        </Grid>
        <Grid container className={Style.container___info}>
        <Grid item  sm={12} xs={12} justify="center" style={{display:'flex'}}>
            <Typography
              variant="h6"
              style={{ marginLeft: "0.5em" }}
            >
              Project Name :
            </Typography>
            <Typography variant="h6" 
              color="textSecondary" style={{ marginLeft: "0.5em" }}>
              {global.config.secureStorage.getItem("projectName")}
            </Typography>
          </Grid>
          <Grid item sm={3} xs={6}>
            <Typography
              variant="h6"
              color="textSecondary"
              style={{ marginLeft: "0.5em" }}
            >
              Generated By
            </Typography>
            <Typography variant="h6" style={{ marginLeft: "0.5em" }}>
              {global.config.secureStorage.getItem("user_fullname")}
            </Typography>
          </Grid>

          <Grid item sm={3} xs={6}>
            <Typography
              variant="h6"
              color="textSecondary"
              style={{ marginLeft: "0.5em" }}
            >
              Generated On
            </Typography>
            <Typography variant="h6" style={{ marginLeft: "0.5em" }}>
              {new Date(new Date()).toISOString().split("T")[0]}
            </Typography>
          </Grid>
          <Grid item sm={3} xs={6}>
            <Typography
              variant="h6"
              color="textSecondary"
              style={{ marginLeft: "0.5em" }}
            >
              Start Date
            </Typography>
            <Typography variant="h6" style={{ marginLeft: "0.5em" }}>
              {startDate}
            </Typography>
          </Grid>
          <Grid item sm={3} xs={6}>
            <Typography
              variant="h6"
              color="textSecondary"
              style={{ marginLeft: "0.5em" }}
            >
              End Date
            </Typography>
            <Typography variant="h6" style={{ marginLeft: "0.5em" }}>
              {endDate}
            </Typography>
          </Grid>
        </Grid>
        <hr />
        <div className={Style.table___box}>
          {props.newWbs !== undefined && (
            <MUIDataTable
              title={
                props.ReportFormat == "Labours Wise"
                  ? `Labour wise supplier report`
                  : "Supplier wise Labour report"
              }
              data={data}
              columns={columns}
              options={{
                selectableRows: "none",
                responsive: smallScreen ? "standard" : "simple",
                pagination: false,
                download: false,
                viewColumns: false,
                filter: false,
                print: false,
              }}
            />
          )}
        </div>
      </div>
    </>
  );
};
export default LaboursReport;
