import React, { useState, useEffect } from "react";
import {
  Card,
  CardActions,
  CardContent,
  Typography,
  Grid,
  Button,
  IconButton,
  Divider,
} from "@material-ui/core";
import Style from "./style.module.scss";

const ViewDetails = ({ materialDetails, toggleViewDetails, stages }) => {
  return (
    <div className="container">
      <Card variant="outlined" className={Style.material__container_2}>
        <CardContent>
          <Grid container item className="mb-2">
            <Grid item xs={12}>
              <Typography variant="body1">
                Created on:- {materialDetails.materialReqDate}
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography variant="body1">
                Id - {materialDetails.materialID}
              </Typography>
            </Grid>
            <Grid item xs={12} style={{ marginTop: "10px" }}>
              <Typography variant="h4">
                Title - {materialDetails.materialReqName}
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography variant="h6">
                Expected Delivery- {materialDetails.expectedDate}
              </Typography>
            </Grid>

            {materialDetails.remark !== undefined ? (
              <Grid item xs={12} style={{ width: "100%" }}>
                <Typography>
                  <strong>Remark :</strong> <br />
                  {materialDetails.remark}
                </Typography>
              </Grid>
            ) : null}

            <Grid item xs={12}>
              <br />
              <Divider />
              <br />
              <Typography variant="h4">Material Details-</Typography>
              <Grid container spacing={2}>
                {materialDetails.data.map((eachMaterial, index) => {
                  return (
                    <Grid item sm={6} className={Style.material__container_2}>
                      <Typography variant="h6">
                        Material Name -{eachMaterial.materialName}
                      </Typography>
                      <Typography variant="h6">
                        Material Quantity- {eachMaterial.materialQuantity}
                      </Typography>
                      <Typography variant="h6">
                        Material Unit- {eachMaterial.materialUnit}
                      </Typography>
                    </Grid>
                  );
                })}
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <br />
              <Divider />
              <br />
              {materialDetails.approvalHistory && (
                <>
                  <Typography variant="h4">Approval Details-</Typography>
                  <Grid container spacing={2}>
                    {materialDetails.approvalHistory.map(
                      (eachMaterial, index) => {
                        return (
                          <Grid
                            item
                            sm={12}
                            className={Style.material__container_2}
                          >
                            <Typography variant="h4">
                              Stage -{stages[eachMaterial.stage].mName}
                            </Typography>
                            {eachMaterial.approvalFile != "" && (
                              <Typography variant="h5">
                                Image- 
                                <a href={eachMaterial.approvalFile} download>
                                <img src={eachMaterial.approvalFile} height="350px"/>
                                </a>
                              </Typography>
                            )}
                            {eachMaterial.quantityData && (
                              <>
                                <Typography variant="h5">Quantity-</Typography>
                                <Grid container>
                                  {eachMaterial.quantityData
                                    ? eachMaterial.quantityData.map(
                                        (eachQuantity, index) => {
                                          return (
                                            <>
                                              <Grid xs={6}>
                                                <Typography variant="h6">
                                                  Material Name-{" "}
                                                  {eachQuantity.materialName}
                                                </Typography>
                                                <Typography variant="h6">
                                                  Material Quantity-{" "}
                                                  {eachQuantity.quantity}
                                                </Typography>
                                              </Grid>
                                            </>
                                          );
                                        }
                                      )
                                    : ""}
                                </Grid>
                              </>
                            )}
                          </Grid>
                        );
                      }
                    )}
                  </Grid>
                </>
              )}
            </Grid>
          </Grid>
        </CardContent>

        <div className={Style.material__container_3}>
          <Button color="secondary" onClick={() => toggleViewDetails("")}>
            Close
          </Button>
        </div>
      </Card>
    </div>
  );
};
export default ViewDetails;
