import React from "react";
import {
  makeStyles,
  Accordion,
  AccordionSummary,
  Typography,
} from "@material-ui/core";
import { Link } from "react-router-dom";
import IconButton from "@material-ui/core/IconButton";

import { Edit,Delete } from "@material-ui/icons";
const useStyles = makeStyles(theme => ({
  root: {
    width: "100%",
  },
  heading: {
    fontSize: theme.typography.pxToRem(18),
    fontWeight: theme.typography.fontWeightRegular,
  },
}));

export default function ActivityList(props) {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      {props.iterList && props.iterList.length !== 0 ? (
        props.iterList.map((data, index) => {
          return (
            <div style={{ display: "flex" }}>
              <Link
                style={{ textDecoration: "none", width: "100%" }}
                to={`/checklist/${props.path}/${data.id}`}
                key={index}
              >
                <Accordion className="my-2">
                  <AccordionSummary
                    aria-controls={"panelContent" + index}
                    id={"panelHeader" + index}
                  >
                     <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "space-between",
                            flexWrap: "wrap",
                            flexGrow: 1,
                          }}
                        >
                    <Typography className={classes.heading}>
                      {data.title}
                    </Typography>
                    <Typography className={classes.heading}>
                      updated on: {data.updatedDate}
                    </Typography>
                    </div>
                  </AccordionSummary>
                </Accordion>
              </Link>
              <IconButton
                aria-label="settings"
                color="primary"
                onClick={() => {
                  props.handleEditModalOpen(index);
                }}
                style={{
                  width: 45,
                  margin: 10,
                }}
              >
                <Edit />
              </IconButton>
              <IconButton
                aria-label="settings"
                color="secondary"
                onClick={() => {
                  props.deleteCheckList(index);
                }}
                style={{
                  width: 45,
                  margin: 10,
                }}
              >
                <Delete />
              </IconButton>
            </div>
          );
        })
      ) : (
        <Typography
          className="mt-5 d-flex justify-content-center align-items-center"
          variant="h4"
          color="textSecondary"
          style={{ textAlign: "center" }}
        >
          No Checklists Available !
        </Typography>
      )}
    </div>
  );
}
