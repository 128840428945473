import React, { Component } from "react";
import {
  Grid,
  Button,
  FormControl,
  Select,
  InputLabel,
  MenuItem,
  Typography,
  List,
  ListItem,
  ListItemText,
  Snackbar,
  Slide,
} from "@material-ui/core";
import { connect } from "react-redux";
import Style from "./style.module.scss";
import { Add, Check as Check, ExpandMore as ExpandMoreIcon } from "@material-ui/icons";
import Notifications from "react-notification-system-redux";
import Loading from "../../../Components/Loading";

import AddMaterial from "./AddMaterial";
import Display from "./Display";
import {
  addMaterial,
  deleteMaterial,
} from "../../../Redux/storeRedux/storeAction";
import {
  getStore,
} from "../../../Redux/materialRedux/materialAction";
class SuperProjectStore extends Component {
  constructor(props) {
    super(props);
    const role = global.config.secureStorage.getItem("role");
    const editRoles = ["purchase_manager", "project_manager", "super_admin"];
    const isEditable = editRoles.find(item => item == role) ? true : false;
    this.state = {
      modalOpen: false,
      loading: true,
      isEditable: true,
      isCreateNewMaterial: false,
      // show:true
    };
  }
  async componentDidMount() {
    await this.props.getStore();
    // await this.props.getStoreData();
  }
  toggleCreateNewMaterial = () => {
    this.setState({
      // allMaterials: newMaterials,
      isCreateNewMaterial: !this.state.isCreateNewMaterial,
    });
  };

  saveMaterial = materialReq => {
    const finalMaterial = {};
    finalMaterial.data = materialReq;

    this.props.addMaterial(finalMaterial);
    this.setState({
      isCreateNewMaterial: !this.state.isCreateNewMaterial,
    });
    window.location.reload()
  };


  
  render() {
    const { isCreateNewMaterial, } = this.state;
    if (isCreateNewMaterial) {
      return  (
        <AddMaterial
          materialReqName={this.state.materialReqName}
          expectedDate={this.state.expectedDateVal}
          storeData={this.props.storeData}
          handleExpectedDate={this.handleExpectedDate}
          handleChangeName={this.handleChangeName}
          saveMaterial={this.saveMaterial}
          toggleCreateNewMaterial={this.toggleCreateNewMaterial}
        />
      );
    } else {
      return (
        <div>
          {this.props.notifications && (
            <Notifications notifications={this.props.notifications} />
          )}
          {this.state.isEditable && (
            <section className={Style.button__display}>
              <Button
                onClick={this.toggleCreateNewMaterial}
                startIcon={<Add />}
                size="large"
              >
                Add Material
              </Button>
            </section>
          )}
    
         <Display isCreateNewMaterial={this.state.isCreateNewMaterial}/>
        </div>
      )
    }




  }
}

const mapStateToProps = state => {
  return {
    notifications: state.notifications,
    storeData: state.materialReducer.storeData,
  };
};

export default connect(mapStateToProps, {
  addMaterial,
  deleteMaterial,
  getStore,
})(SuperProjectStore);
