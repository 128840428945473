import React from "react";
import {
  makeStyles,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
} from "@material-ui/core";
import { ExpandMore as ExpandMoreIcon } from "@material-ui/icons";
import { Link } from "react-router-dom";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";

const useStyles = makeStyles(theme => ({
  root: {
    width: "100%",
    marginBottom: "1rem",
    marginTop: "1em",
    display: "flex",
    flexWrap: "wrap",
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    fontWeight: theme.typography.fontWeightRegular,
  },
}));

function MainList(props) {
  // console.log(props.percentage);

  function revisedRandId() {
    return Math.random()
      .toString(36)
      .replace(/[^a-z]+/g, "")
      .substr(2, 10);
  }
  const classes = useStyles();
  const checkEmpty = subActivity => {
    let temp = subActivity.filter(
      eachSubActivity => eachSubActivity.visibility === true
    );
    if (temp.length === 0) {
      return true;
    }
    return false;
  };
  return (
    <DragDropContext>
      <>
        <Droppable droppableId={"infratable"}>
          {provided => (
            <div
              className={classes.root}
              ref={provided.innerRef}
              {...provided.droppableProps}
            >
              {props.defaultActivity &&
                props.defaultActivity.map((data, index) => {
                  if (data.visibility) {
                    return (
                      <Draggable
                        key={index}
                        index={index}
                        draggableId={`panelHeader${index}`}
                      >
                        {provided => (
                          <div
                            style={{
                              display: "flex",
                              flexDirection: "row",
                              width: "100%",
                              padding: "10px",
                            }}
                          >
                            <div style={{ display: "inline-block", flex: 1 }}>
                              <Accordion
                                className="mb-1"
                                TransitionProps={{ unmountOnExit: true }}
                                ref={provided.innerRef}
                                {...provided.draggableProps}
                                key={index}
                              >
                                <AccordionSummary
                                  expandIcon={
                                    <ExpandMoreIcon
                                      fontSize="large"
                                      color="secondary"
                                    />
                                  }
                                  aria-controls={"panelContent" + index}
                                  id={"panelHeader" + index}
                                >
                                  <div
                                    style={{
                                      width: "100%",
                                      display: "flex",
                                      alignItems: "center",
                                      justifyContent: "space-between",
                                    }}
                                    {...provided.dragHandleProps}
                                  >
                                    <Typography className={classes.heading}>
                                      {data.title}
                                    </Typography>
                                    <Typography
                                      style={{ marginLeft: "20px" }}
                                      color="textSecondary"
                                    >
                                      {props.percentage[index] !== undefined &&
                                        props.percentage[index] !== null
                                        ? props.percentage[index]?.toFixed(2) //add toFixed
                                        : "0.00"}
                                      %
                                    </Typography>
                                  </div>
                                </AccordionSummary>
                                {data.content !== null
                                  ? data.content.map((value, subIndex) => {
                                    if (value.visibility) {
                                      return (
                                        <div key={subIndex}>
                                          <hr
                                            style={{
                                              padding: "5px",
                                              margin: 0,
                                            }}
                                          />
                                          <Link
                                            style={{ textDecoration: "none" }}
                                            to={{
                                              state: {
                                                roadActivityIndex:
                                                  props.activeActivityIndex,
                                              },
                                              pathname: `/activities/${data.activity}/${value.link}`,
                                            }}
                                          >
                                            <AccordionDetails
                                              style={{
                                                width: "100%",
                                                display: "flex",
                                                alignItems: "center",
                                                justifyContent:
                                                  "space-between",
                                                padding: "10px 50px",
                                              }}
                                            >
                                              {value.subTitle}
                                              <Typography
                                                style={{ marginLeft: "20px" }}
                                                color="textSecondary"
                                              >
                                                {props.subActivityPercentage && props.subActivityPercentage
                                                  .length != 0 && props.subActivityPercentage[index] && 
                                                  props.subActivityPercentage[
                                                  index
                                                  ][subIndex] !== undefined &&
                                                  props.subActivityPercentage[
                                                  index
                                                  ][subIndex] !== null
                                                  ? props.subActivityPercentage[
                                                    index
                                                  ][subIndex]?.toFixed(2) //add toFixed
                                                  : "0.00"}
                                                %
                                              </Typography>
                                            </AccordionDetails>
                                          </Link>
                                        </div>
                                      );
                                    }
                                  })
                                  : ""}

                                {data.content !== null &&
                                  checkEmpty(data.content) ? (
                                  <div>
                                    <hr style={{ padding: "5px", margin: 0 }} />
                                    <Link
                                      style={{ textDecoration: "none" }}
                                      to={`/activities/${data.activity}/null`}
                                    >
                                      <AccordionDetails
                                        style={{ paddingLeft: "50px" }}
                                      >
                                        {"Update Here"}
                                      </AccordionDetails>
                                    </Link>
                                  </div>
                                ) : (
                                  ""
                                )}
                              </Accordion>
                            </div>
                            {provided.placeholder}
                          </div>
                        )}
                      </Draggable>
                    );
                  }
                })}
            </div>
          )}
        </Droppable>
      </>
    </DragDropContext>
  );
}

export default MainList;