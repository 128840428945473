import React from "react";

import { useTheme } from "@material-ui/core/styles";
import {
  Button,
  TextField,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  useMediaQuery,
  Typography,
  Grid,
} from "@material-ui/core";
import { makeStyles } from '@material-ui/core/styles';
//{}
const useStyles = makeStyles(theme => ({
  dialogPaper: {
     
      height : '400px'
  },
}));

export default function ConfirmationDialog(props) {
  const theme = useTheme();
  console.log("inside confimration",props)
  const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));
 
  
  return (
    <div >
   {props.isDialogOpen &&  <div>
      <Dialog open={true} fullScreen={fullScreen}>
        <DialogContent>
          <div>
            <Typography variant="p" style={{ color: "red" }}>
              Do you really want to save changes?
            </Typography>
          </div>
        </DialogContent>
        <DialogActions>
          <Button onClick={props.submitActivityList} color="secondary">
            yes
          </Button>
          <Button onClick={props.changeAct} color="primary">
            no
          </Button>
        </DialogActions>
      </Dialog>
    </div>}
    {props.isImportConflict &&
    <div>
      <Dialog open={true} 
       style={{"height":"50vh","display":"flex", "align-items":"center","justifyContent":"center","marginTop":"20vh"}}
     
      >
        <DialogContent>
         
              {props.ImportAccErrors.length >0 ? (props.ImportAccErrors.map( issue =>{
                   
                   return  <p>{`issue on line: ${issue.rowNo}: ${issue.error_text} `}</p>
                   
           })): "No errors found, would you like to continue"}
          
        </DialogContent>
        <DialogActions>
          <Button onClick={props.overwriteImport} color="secondary">
            yes
          </Button>
          <Button onClick={props.cancelImport} color="primary">
            no
          </Button>
        </DialogActions>
      </Dialog>
    </div>

    }
    </div>
  );
}
