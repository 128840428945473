import React from "react";

import { useTheme } from "@material-ui/core/styles";
import {
  Button,
  TextField,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  useMediaQuery,
  Grid,
  Typography,
} from "@material-ui/core";
import DateFnsUtils from "@date-io/date-fns";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers";
export default function AddTasks(props) {
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));
  return (
    <div>
      <Dialog open={props.open} fullScreen={fullScreen}>
        <DialogTitle>
          {props.isEdit ? "Edit" : "New"} {props.isSubTask ? "SubTask" : "Task"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            Provide a {props.isEdit ? "Edit" : "New"}{" "}
            {props.isSubTask ? "SubTask" : "SubTask"}
          </DialogContentText>
          <TextField
            margin="dense"
            id="taskName"
            name={props.isSubTask ? "taskSubName" : "taskName"}
            label="Task Name"
            error={props.taskSameNameError}
            helperText={props.taskSameNameError}
            type="text"
            variant="standard"
            value={props.isSubTask ? props.taskSubName : props.taskName}
            // error={props.alertSubActivity}
            // helperText={props.alertSubActivityField}
            onChange={event => {
              let name = props.isSubTask ? "taskSubName" : "taskName";
              props.handleTaskChange(event.target.value, name);
              props.handletaskSameNameError(event.target.value)
            }}
            fullWidth
          />
          {!props.isSubTask ? (
            <>

          <TextField
            margin="dense"
            id="taskcost"
            name="taskcost"
            label="task cost"
            type="number"
            variant="standard"
            value={props.taskcost}
            onChange={(event) =>{
              props.handleTaskChange(event.target.value,"taskcost")
            }}
            required
            fullWidth
          />
              <Typography>Estimate Start Date :</Typography>
              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <KeyboardDatePicker
                  disableToolbar
                  placeholder="Not available"
                  format="dd/MM/yyyy"
                  helperText={props.error}
                  margin="none"
                  id="date-picker-inline"
                  KeyboardButtonProps={{
                    "aria-label": "Estimate Start Date",
                  }}
                  value={props.taskEstimateStart}
                  onChange={event => {
                    let name = "taskEstimateStart";
                    props.handleTaskChange(event, name);
                  }}
                />
              </MuiPickersUtilsProvider>
              <Typography>Estimate End Date :</Typography>
              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <KeyboardDatePicker
                  disableToolbar
                  format="dd/MM/yyyy"
                  placeholder="Not available"
                  margin="none"
                  helperText={props.error}
                  id="date-picker-inline"
                  KeyboardButtonProps={{
                    "aria-label": "Estimate End Date",
                  }}
                  value={props.taskEstimateEnd}
                  onChange={event => {
                    let name = "taskEstimateEnd";
                    props.handleTaskChange(event, name);
                  }}
                />
              </MuiPickersUtilsProvider>
            </>
          ) : (
            ""
          )}

          {/* <TextField
            margin="dense"
            id="taskQuantity"
            name={props.isSubTask ? "taskSubQuantity" : "taskQuantity"}
            label="Estimate Start Date"
            type="number"
            variant="standard"
            value={props.isSubTask ? props.taskSubQuantity : props.taskQuantity}
            // error={props.alertSubActivity}
            // helperText={props.alertSubActivityField}
            onChange={props.handleTaskChange}
            fullWidth
          />
          <TextField
            margin="dense"
            id="taskUnit"
            name={props.isSubTask ? "taskSubUnit" : "taskUnit"}
            label="Estimate End Date"
            type="text"
            variant="standard"
            value={props.isSubTask ? props.taskSubUnit : props.taskUnit}
            // error={props.alertSubActivity}
            // helperText={props.alertSubActivityField}
            onChange={props.handleTaskChange}
            fullWidth
          /> */}
        </DialogContent>
        <DialogActions>
          <Button onClick={props.handleModalOpen} color="secondary">
            Cancel
          </Button>
          {/* <Button onClick={props.submitTask} color="primary"> */}
          <Button
            onClick={props.isSubTask ? props.submitSubTask : props.submitTask}
            color="primary"
          >
            Submit
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}