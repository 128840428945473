import React from "react";
import { Grid, TextField } from "@material-ui/core";
import Autocomplete, {
  createFilterOptions,
} from "@material-ui/lab/Autocomplete";
const Step1 = props => {
  const filter = createFilterOptions();

  let projects = [...props.userProjects]
  let tempSProjects = []
  let defaultval={};
  projects.forEach((singleProject, id) => {
    if (singleProject.superProject)
      tempSProjects.push({ id: id, value: singleProject.superProject })
  });

  let uniqueArray = tempSProjects.filter((v, i, a) => a.findIndex(v2 => (v2.value === v.value)) === i)
  uniqueArray.forEach(val =>{
    if(val.value===props.superProject) {
      defaultval=val;
      return;
    }
  })
    console.log("unique array is here",uniqueArray,defaultval)
  return (
    <div className="container mt-2">
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <TextField
            name="projectName"
            variant="outlined"
            required
            fullWidth
            value={props.projectName}
            onChange={props.handleChange}
            id="projectName"
            label="Project Name"
            autoFocus
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            fullWidth
            name="projectDescription"
            label="Location"
            multiline
            placeholder="Project Location"
            rows={4}
            variant="outlined"
            value={props.projectDescription}
            onChange={props.handleChange}
          />
        </Grid>

        <Grid item>
          {/* <TextField
            id="outlined-select-currency-native"
            select
            label="Type of Project"
            name="projectType"
            value={props.projectType}
            onChange={props.handleChange}
            SelectProps={{
              native: true,
            }}
          >
            {Types.map(option => (
              <option key={option.key} value={option.value}>
                {option.value}
              </option>
            ))}
          </TextField> */}
           <Autocomplete
            style={{ width: 500 }}
            value={defaultval}
            options={uniqueArray}
            selectOnFocus
            clearOnBlur
            handleHomeEndKeys
            getOptionSelected={(option, value) => option.value === value.value}
            filterOptions={(options, params) => {
              const filtered = filter(options, params);
              if (params.inputValue !== "") {
                filtered.push({
                  value: params.inputValue,
                  title: `Add "${params.inputValue}"`,
                });
              }
              return filtered;
            }}
            onChange={props.handleDropdownChange}
            getOptionLabel={option => {
              if (typeof option === "string") {
                return option;
              }
              if (option.value) {
                return option.value;
              }

              return option.title;
            }}
            renderOption={option => option.title ? option.title : option.value}
            className="my-3"
            freeSolo
            fullWidth
            renderInput={params => (
              <TextField
                {...params}
                name="superProject"
                label="Super Project Name"
                variant="outlined"
                fullWidth
              />
            )}
          />
        </Grid>
      </Grid>
    </div>
  );
};

export default Step1;
