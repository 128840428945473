import {
  GET_UPDATES_DETAILS,
  SUCCESS_GET_UPDATES_DETAILS,
  ERROR_GET_UPDATES_DETAILS,
} from "./overviewActionTypes";

const initialState = {
  loading: true,
  updateActivity: [],
  error: "",
  activityDetails: [],
};

const overviewReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_UPDATES_DETAILS:
      return {
        ...state,
        loading: true,
      };
    case SUCCESS_GET_UPDATES_DETAILS:
      return {
        ...state,
        activityDetails: action.payload,
        loading: false,
      };
    case ERROR_GET_UPDATES_DETAILS:
      return {
        ...state,
      };
    default:
      return {
        ...state,
      };
  }
};

export default overviewReducer;
