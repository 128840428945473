import React, { Component } from "react";
import {
  Grid,
  Typography, InputLabel,
  Select,
  MenuItem,
  DialogActions,

  FormControl,
} from "@material-ui/core";
import { Group, CheckBox, FilterList, ErrorRounded } from "@material-ui/icons";
import { connect } from "react-redux";
import IconButton from "@material-ui/core/IconButton";

import Loading from "../../Components/Loading";
import BarchartData from "./Barchart";
import VerticleList from "./VerticleList";
import {
  getActivities,
} from "../../Redux/monitoringRedux/monitoringAction";
import { getDefaultActivities } from "../../Redux/activityRedux/activityAction";
import { getAllUpdatesDetails } from "../../Redux/overviewRedux/overviewAction";
import axios from "axios";
import { Link } from "react-router-dom";

class Overview extends Component {
  constructor(props) {
    // console.log(iterList);
    super(props);
    const role = global.config.secureStorage.getItem("role");
    const roles = ["project_manager", "chief_engineer", "super_admin"];
    // const isAddVisible = roles.find(item => item == role) ? true : false;

    this.state = {
      subProject: {},
      loading: true,
      activeActivityIndex: 0,
      percentage: [],
      accNames: [],
      projectType: "",
      activityDetails: [],
      safetyCheckList: 0,
      safetyIssue: 0,
      qualityCheckList: 0,
      qualityIssue: 0,
      drpDataCount: 0,
      todoData: 0,
      actualCompletionByPercent: [],
      estimatedCompletionByPercent: [],
      actualCompletionByCost: [],
      estimatedCompletionByCost: [],
      activityNames: [],
      subactivityNames: [],
      monitoring: [],
      selectedActivity: '',
      selectedSubActivity: '',
      taskNames: []
    };
  }

  async componentDidMount() {
    try {
      this.props.getActivities();
      const response = await axios.get(
        `${global.config.backendURL
        }/overview/getAllStatisticalData/${global.config.secureStorage.getItem(
          "projectId"
        )}/${global.config.secureStorage.getItem("subprojectId")}`
      );
      response.data.forEach(data => {
        if (data.type === "checklist") {
          this.setState({
            safetyCheckList: data.safety,
            qualityCheckList: data.quality,
          });
        }
        if (data.type === "issue") {
          this.setState({
            safetyIssue: data.safety,
            qualityIssue: data.quality,
          });
        }
        if (data.type === "todoData") {
          this.setState({ todoData: data.total });
        }
        if (data.type === "dpr") {
          this.setState({ drpDataCount: data.count });
        }
      });

      await this.props.getDefaultActivities();
      await this.props.getAllUpdatesDetails(
        global.config.secureStorage.getItem("subprojectId")
      );

      let newPer = [];
      let newData = [];

      let DataAcc = this.props.percentage.accName;
      let DataList = this.props.percentage.per;
      DataList.forEach((element, i) => {
        if (element != 0) {
          newPer.push(element);
          newData.push(DataAcc[i]);
        }
      });
      if (this.props.activityDetails)
        this.setState({
          activityDetails: this.props.activityDetails,
        });
      this.setState({
        loading: !this.state.loading,
        percentage: newPer,
        accNames: newData,

      });







      const cost = [...this.props.cost]

      if (this.props.cost.length > 0) {
        let tempEstCost = []
        let tempActCost = []
        let tempAccNames = []
        cost.forEach((elem, id) => {
          if (elem.visibility) {

            tempEstCost.push(elem.estimatedcompletion_by_cost)

            tempActCost.push(elem.actualcompletion_by_cost)
            tempAccNames.push(elem.title)
          }
        })
        this.setState({
          estimatedCompletionByCost: tempEstCost,
          actualCompletionByCost: tempActCost,
          accNames: tempAccNames
        });
      }
      // this.fetchData();
    } catch (error) {
      //
    }
  }


  componentDidUpdate(pP, pS, sS) {
    try {
      const data = [...this.props.monitoring]



      if (this.props.monitoring && this.state.monitoring.length <= 0) {

        let activityArr = []

        data.forEach((elem, id) => {
          activityArr.push(elem.activity)
          let tempSub = []
          elem.subActivities.forEach((elem2, id) => {
            if (elem2 != null) {
              tempSub.push(elem2.title)
            }
          })

        })

        this.setState({
          monitoring: this.props.monitoring,
          activityNames: activityArr,
        });
      }






      if (pS.selectedActivity != this.state.selectedActivity) {
        let subactivityArr = []
        data.forEach((elem, id) => {
          if (this.state.selectedActivity == elem.activity) {
            elem.subActivities.forEach((elem2, id) => {
              if (elem2 != null) {
                subactivityArr.push(elem2.title)
              }
            })
          }
        })
        this.setState({
          subactivityNames: subactivityArr,
          actualCompletionByPercent: [],
          estimatedCompletionByPercent: [],
          taskNames: []
        });
      }

      if (pS.selectedSubActivity != this.state.selectedSubActivity) {
        this.setState({
          actualCompletionByPercent: [],
          estimatedCompletionByPercent: [],
          taskNames: []
        }, () => {
          data.forEach((elem, id) => {
            if (elem != null && this.state.selectedActivity == elem.activity) {
              elem.subActivities.forEach((elem2, id) => {
                if (elem2 != null && this.state.selectedSubActivity == elem2.title) {
                  this.setState({
                    actualCompletionByPercent: elem2.tasks.TaskDetails.Taskdata,
                    estimatedCompletionByPercent: elem2.tasks.TaskDetails.dateProgress,
                    taskNames: elem2.tasks.TaskDetails.TaskNames
                  });
                }
              })
            }
          })
        });
      }
    } catch (error) {

    }

  }

  render() {
    if (this.state.loading) {
      return <Loading loading={this.state.loading} />;
    }

    return (
      <div className="container" style={{ marginTop: "20px" }}>
        <Grid container spacing={2}>
          <Grid
            style={{
              backgroundColor: "#fff",
            }}
            item
            sm={8}
            xs={12}
          >
            <Grid container spacing={2}>
              <Grid className="mt-2" item sm={2} xs={6}>
                <Grid
                  className="p-4"
                  style={{
                    textAlign: "center",
                    height: "100%",
                    border: "1px solid #ddd",
                  }}
                >
                  <Link to="/dpr">
                    <IconButton aria-label="settings">
                      <Group fontSize="large" color="disabled" />
                    </IconButton>
                  </Link>
                  <Typography variant="h6">
                    {this.state.drpDataCount}
                  </Typography>
                  <Typography variant="h6">Dprs</Typography>
                </Grid>
              </Grid>
              <Grid className="mt-2" item sm={2} xs={6}>
                <Grid
                  className="p-4"
                  style={{
                    textAlign: "center",
                    height: "100%",
                    border: "1px solid #ddd",
                  }}
                >
                  <Link to="/todo">
                    <IconButton aria-label="settings">
                      <Group fontSize="large" color="disabled" />
                    </IconButton>
                  </Link>
                  <Typography variant="h6">{this.state.todoData}</Typography>
                  <Typography variant="h6">Todos</Typography>
                </Grid>
              </Grid>
              <Grid className="mt-2" item sm={2} xs={6}>
                <Grid
                  className="p-4"
                  style={{
                    textAlign: "center",
                    height: "100%",
                    border: "1px solid #ddd",
                  }}
                >
                  <Link to="/checklist/quality">
                    <IconButton aria-label="settings">
                      <FilterList fontSize="large" color="disabled" />
                    </IconButton>
                  </Link>
                  <Typography variant="h6">
                    {this.state.qualityCheckList}
                  </Typography>
                  <Typography variant="h6">Quality Check list</Typography>
                </Grid>
              </Grid>
              <Grid className="mt-2" item sm={2} xs={6}>
                <Grid
                  className="p-4"
                  style={{
                    textAlign: "center",
                    height: "100%",
                    border: "1px solid #ddd",
                  }}
                >
                  <Link to="/checklist/safety">
                    <IconButton aria-label="settings">
                      <FilterList fontSize="large" color="disabled" />
                    </IconButton>
                  </Link>
                  <Typography variant="h6">
                    {this.state.safetyCheckList}
                  </Typography>
                  <Typography variant="h6">Safety Check list</Typography>
                </Grid>
              </Grid>
              <Grid className="mt-2" item sm={2} xs={6}>
                <Grid
                  className="p-4"
                  style={{
                    textAlign: "center",
                    height: "100%",
                    border: "1px solid #ddd",
                  }}
                >
                  <Link to="/issues/quality">
                    <IconButton aria-label="settings">
                      <ErrorRounded fontSize="large" color="disabled" />
                    </IconButton>
                  </Link>
                  <Typography variant="h6">
                    {this.state.qualityIssue}
                  </Typography>
                  <Typography variant="h6">Quality Issue</Typography>
                </Grid>
              </Grid>
              <Grid className="mt-2" item sm={2} xs={6}>
                <Grid
                  className="p-4"
                  style={{
                    textAlign: "center",
                    height: "100%",
                    border: "1px solid #ddd",
                  }}
                >
                  <Link to="/issues/safety">
                    <IconButton aria-label="settings">
                      <ErrorRounded fontSize="large" color="disabled" />
                    </IconButton>
                  </Link>
                  <Typography variant="h6">{this.state.safetyIssue}</Typography>
                  <Typography variant="h6">Safety Issue</Typography>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <Grid item sm={4} xs={12}>
            <VerticleList activityDetails={this.state.activityDetails} />
          </Grid>
        </Grid>
        <Grid container className="mt-4" spacing={2}>
          <Grid container sm={8} xs={12}>
            <Grid item sm={6} xs={12}>
              <FormControl fullWidth>
                <InputLabel id="activity" required>
                  Activity
                </InputLabel>
                <Select
                  labelId="activity"
                  id="activity"
                  value={this.state.selectedActivity}
                  label="activity"
                  onChange={event => {
                    this.setState({
                      selectedActivity: event.target.value
                    });
                  }}
                >
                  {this.state.activityNames.map(item => (
                    <MenuItem value={item}>{item}</MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid item sm={6} xs={12}>
              <FormControl fullWidth>
                <InputLabel id="activity" required>
                  Sub Activity
                </InputLabel>
                <Select
                  labelId="subactivity"
                  id="subactivity"
                  value={this.state.selectedSubActivity}
                  label="subActivity"
                  onChange={event => {
                    this.setState({
                      selectedSubActivity: event.target.value
                    });
                  }}
                >
                  {this.state.subactivityNames.map(item => (
                    <MenuItem value={item}>{item}</MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>


            <Grid item sm={6} xs={12}>
              {this.state.taskNames &&
                this.state.estimatedCompletionByPercent &&
                this.state.taskNames.length > 0 ? (
                <BarchartData
                  accNames={this.state.taskNames}
                  percentage={this.state.estimatedCompletionByPercent}
                  percentageLabel="Estimated Completion by Percentage"
                  percentage1={this.state.actualCompletionByPercent}
                  percentage1Label="Actual Completion by Percentage"
                />
              ) : (
                <Typography> [Select Activity and Sub Activity*]</Typography>
              )}
            </Grid>
            <Grid item sm={6} xs={12}>

              {this.state.accNames &&
                this.state.actualCompletionByCost &&
                this.state.accNames.length > 0 ? (
                <BarchartData
                  accNames={this.state.accNames}
                  percentage={this.state.estimatedCompletionByCost}
                  percentageLabel="Estimated Completion by Cost"
                  percentage1={this.state.actualCompletionByCost}
                  percentage1Label="Actual Completion by Cost"
                />
              ) : (
                ""
              )}
            </Grid>
          </Grid>

          <Grid item sm={4} xs={12}>
            {/* {console.log(this.state.accNames)} */}
            {this.state.accNames.length > 0 ? (
              <BarchartData
                accNames={this.state.accNames}
                percentage={this.state.percentage}
                percentageLabel="Activity Updates"
              />
            ) : (
              ""
            )}
          </Grid>

        </Grid>
      </div>
    );
  }
}

const mapStateToProps = state => {
  console.log(state.monitoringReducer.monitoring)
  return {
    defaultActivity: state.activityReducer.defaultActivity,
    percentage: state.activityReducer.percentage,
    loading: state.activityReducer.loading,
    activityDetails: state.overviewReducer.activityDetails,
    monitoring: state.monitoringReducer.monitoring,
    cost: state.monitoringReducer.cost,
  };
};

export default connect(mapStateToProps, {
  getDefaultActivities,
  getAllUpdatesDetails,
  getActivities
})(Overview);
